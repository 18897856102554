import au from "./au/index.js";
import es from "./es/index.js";
import it from "./it/index.js";
import ro from "./ro/index.js";
import uk from "./uk/index.js";
import staging from "./staging/index.js";
import commons from "./commons.js";

const countriesConfs = {
  au: au,
  es: es,
  it: it,
  ro: ro,
  uk: uk,
  "it-staging": staging,
};

const getConfs = (country = "it") => {
  const countryConfs = countriesConfs[country];
  // NOTE: process isn't defined during the Sentry startup
  return {
    ...countryConfs,
    ...commons,
    appVersion: process?.env?.npm_package_version,
  };
};

export default getConfs;
