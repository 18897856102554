import { useSelectorPQGlobalThis } from "./usePQGlobalThis";
import replace from "lodash/replace";
import PqConsole from "@/commons/utilities/PqConsole";

const replaceParams = (value, params) => {
  if (params) {
    Object.keys(params).forEach((k) => {
      value = replace(value, new RegExp(`%{${k}}`, "g"), params[k]);
    });
  }
  return value;
};

const usePQTranslations = (): ((path: any, params?: {}) => string) => {
  return (path, params = {}) => {
    const { pqLanguageTranslate } = useSelectorPQGlobalThis();

    try {
      const keys = path.split(".");
      let value = pqLanguageTranslate;
      for (var i = 0; i < keys.length; i++) {
        const key = keys[i];
        value = value[key];
        if (!value) {
          throw new Error(`PQ TRANSLATE: ${path} not found`);
        }
      }
      return replaceParams(value, params);
    } catch (error) {
      PqConsole.error("usePQTranslations", error);
      return path;
    }
  };
};

export default usePQTranslations;
