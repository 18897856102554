import Model from "./Model";

const setHours = (hours) => {
  hours = hours.map((hour) => {
    var splittedString = hour.split(":");
    hour = splittedString.slice(0, -1).join(":");
    return hour;
  });

  if (!hours) {
    return null;
  } else if (hours.length == 2) {
    return [[hours[0], hours[1]]];
  } else if (hours.length == 4) {
    return [
      [hours[0], hours[1]],
      [hours[2], hours[3]],
    ];
  }
  return null;
};

class OpeningHoursModel extends Model {
  constructor(openingHours = {}) {
    super(openingHours);

    this.weekday = openingHours.weekday;
    this.open = openingHours.open;
    this.openPM = openingHours.openPM || openingHours.open_pm;
    this.close = openingHours.close;
    this.closePM = openingHours.closePM || openingHours.close_pm;
    this.closed = openingHours.closed;
    this.hours = setHours(openingHours.hours);
  }

  get isClosed() {
    return this.closed;
  }

  static getCollection = (data) => data.map((d) => new OpeningHoursModel(d));
}

export default OpeningHoursModel;
