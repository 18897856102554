import Queries from "./Queries";
import CityModel from "@/commons/dataset/models/CityModel";
import CitiesFragments from "@/commons/dataset/fragments/CitiesFragments";

class CityQueries extends Queries {
  getResult(result, returnJson = false) {
    const dataResult = result?.data?.City;
    if (dataResult) {
      if (returnJson) {
        return dataResult;
      } else {
        const city = new CityModel(dataResult);
        return city;
      }
    }
    return null;
  }

  storeCount(params) {
    const defaults = {
      citySlug: null,
      fragment: CitiesFragments.storeCount(),
      returnJson: false,
    };
    params = { ...defaults, ...params };

    const { citySlug, fragment, returnJson } = params;

    const name = `City_storeCount($slug:String)`;
    const data = `...${fragment.name}`;
    const query = `City(slug: $slug)`;

    const variables = {
      slug: citySlug,
    };

    return this.execQuery(name, query, data, [fragment], variables, returnJson);
  }
}

export default CityQueries;
