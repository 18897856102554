import Model from "./Model";

class DeviceModel extends Model {
  constructor(device = {}) {
    super(device);
    this.firebaseToken = device.firebase_token || device.firebaseToken;
    this.deviceType = device.device_type || device.deviceType;
    this.platform = device.platform;
    this.appVersion = device.app_version || device.appVersion;
    this.lat = device.latitude || device.lat;
    this.lon = device.longitude || device.lon;
    this.city = device.city;
  }

  static getCollection = (data) => data.map((d) => new DeviceModel(d));
}

export default DeviceModel;
