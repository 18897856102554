import Model from "./Model";

class CountyModel extends Model {
  constructor(country = {}) {
    super(country);
    this.name = country.name;
  }

  static getCollection = (data) => data.map((d) => new CountyModel(d));
}

export default CountyModel;
