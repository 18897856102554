import EnvUtility from "./EnvUtility";

class Navigator {
  static getInfoBrowser(rStateHeaders) {
    const userAgent = rStateHeaders?.ua;

    const isChrome = userAgent.indexOf("Chrome") > -1;
    const isExplorer =
      userAgent.indexOf("MSIE") > -1 || userAgent.indexOf("Trident") > -1;
    const isExplorer11 = userAgent.indexOf("MSIE 11") > -1;
    const isEdge = userAgent.indexOf("Edge") > -1;
    const isFirefox = userAgent.indexOf("Firefox") > -1;
    const isSafari =
      userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") < 0;
    const isOpera = userAgent.toLowerCase().indexOf("op") > -1;
    const isAppleDevice = userAgent.match(/(iPhone|iPod|iPad)/i) != null;

    return {
      isChrome,
      isExplorer,
      isExplorer11,
      isEdge,
      isFirefox,
      isSafari,
      isOpera,
      isAppleDevice,
    };
  }

  static isMobile(rStateHeaders) {
    if (rStateHeaders?.device) {
      return rStateHeaders?.device?.type == "mobile";
    } else {
      const userAgent = rStateHeaders?.ua;
      const isMobile =
        (Navigator.isAndroidDevice ||
          userAgent.match(/webOS/i) ||
          userAgent.match(/iPhone/i) ||
          userAgent.match(/iPad/i) ||
          userAgent.match(/iPod/i) ||
          userAgent.match(/BlackBerry/i) ||
          userAgent.match(/IEMobile/i) ||
          userAgent.match(/Opera Mini/i) ||
          userAgent.match(/Windows Phone/i)) != null;

      return isMobile;
    }
  }

  static isTablet(rStateHeaders) {
    if (rStateHeaders?.device) {
      return rStateHeaders?.device?.type == "tablet";
    } else {
      return /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
        userAgent,
      );
    }
  }
  static isAndroidDevice(rStateHeaders) {
    return rStateHeaders.os.name === "Android";
  }

  static isDesktop(rStateHeaders) {
    return (
      !Navigator.isMobile(rStateHeaders) && !Navigator.isTablet(rStateHeaders)
    );
  }

  static isWebView() {
    const urlParams = new URLSearchParams(window?.location?.search);
    const result = urlParams.get("lat") && urlParams.get("lng");
    return result;
  }

  static isSlowMobile(rStateHeaders) {
    if (EnvUtility.isClientSide) {
      return (
        (Navigator.isMobile(rStateHeaders) ||
          Navigator.isTablet(rStateHeaders)) &&
        navigator.hardwareConcurrency <= 2 &&
        navigator.deviceMemory <= 2
      );
    } else {
      return false;
    }
  }
}

export default Navigator;
