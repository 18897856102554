import { from } from "@apollo/client";

import { authLink } from "./auth";
import { batchHttpLink } from "./batch-http";
import { errorLink } from "./error";
//import { platformLink } from './platform'
//import { retryLink } from './retry'

export const link = from([
  errorLink,
  //retryLink,
  authLink,
  //platformLink,
  batchHttpLink,
]);
