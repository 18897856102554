import TokenMutations from "@/commons/dataset/mutations/TokenMutations";
import { useSelectorPQGlobalThis } from "@/commons/hooks/usePQGlobalThis";

class TokenManager {
  static urlClient = null;
  static apiKey = null;
  static device = null;
  static token = null;
  static prToken = null;
  static prNewToken = null;
  static prRefreshToken = null;

  static settings(urlClient, apiKey, device) {
    const { pqConfs } = useSelectorPQGlobalThis();
    TokenManager.urlClient = urlClient || pqConfs.gql.domainGqlAppauth;
    TokenManager.apiKey = apiKey || pqConfs.gql.apiKey;
    TokenManager.device = device || pqConfs.gql.device;
  }

  static validSettings() {
    if (
      !TokenManager.urlClient ||
      !TokenManager.apiKey ||
      !TokenManager.device
    ) {
      TokenManager.settings();
    }
    return true;
  }

  static getToken() {
    if (!TokenManager.validSettings())
      throw "PQ ERROR: inizializzare TokenManager";

    if (!TokenManager.prNewToken) {
      TokenManager.prRefreshToken = null;
      TokenManager.prNewToken = new Promise(async (resolve, reject) => {
        if (TokenManager.token) {
          resolve(TokenManager.token);
        } else {
          try {
            const params = {
              apiKey: TokenManager.apiKey,
              device: TokenManager.device,
            };
            const token = await new TokenMutations(TokenManager.urlClient).get(
              params,
            );
            TokenManager.token = token;
            resolve(TokenManager.token);
          } catch (error) {
            reject(error);
          }
        }
      });
    }

    return TokenManager.prNewToken;
  }

  static refreshToken() {
    if (!TokenManager.validSettings())
      throw "PQ ERROR: inizializzare TokenManager";

    if (!TokenManager.prRefreshToken) {
      TokenManager.prNewToken = null;
      TokenManager.prRefreshToken = new Promise(async (resolve, reject) => {
        try {
          if (TokenManager.token) {
            const token = await new TokenMutations(
              TokenManager.urlClient,
            ).renewal(TokenManager.token.value);
            TokenManager.token = token;
            resolve(TokenManager.token);
          } else {
            const params = {
              apiKey: TokenManager.apiKey,
              device: TokenManager.device,
            };
            const token = await new TokenMutations(TokenManager.urlClient).get(
              params,
            );
            TokenManager.token = token;
            resolve(TokenManager.token);
          }
        } catch (error) {
          reject(error);
        }
      });
    }
    return TokenManager.prRefreshToken;
  }
}

export default TokenManager;
