import Model from "./Model";
import CityModel from "./CityModel";
import { LocationConstants } from "@/commons/dataset/constants/LocationConstants";

class LocationModel extends Model {
  constructor(location = {}) {
    super(location);
    this.id = this.id || location.city ? location.city.id : null;
    this.type = location.type;
    this.default = location.default;
    this.fromUrl = location.from_url || location.fromUrl;
    this.fromBot = location.from_bot || location.fromBot;
    this.fromIp = location.from_ip || location.fromIp;
    this.latitude = location.latitude;
    this.longitude = location.longitude;
    this.shortAddress = location.short_address || location.shortAddress;
    this.fullAddress = location.full_address || location.fullAddress;
    this.slug = location.slug;
    this.county = location.county;
    this.zipCode = location.zip_code || location.zipCode;
    this.storeCount = location.storeCount;
    this.city = location.city ? new CityModel(location.city) : null;
    this.location2 = location.location2;
  }

  get isFromIP() {
    return this.type == LocationConstants.IP;
  }
  get isFromGPS() {
    return this.type == LocationConstants.GPS;
  }
  get isFromUser() {
    return this.type == LocationConstants.USER;
  }
  get isFromURL() {
    return this.type == LocationConstants.URL;
  }
  get isFromDefault() {
    return this.type == LocationConstants.DEFAULT;
  }
  get isFromBot() {
    return this.type == LocationConstants.BOT;
  }

  get isPrecise() {
    return this.type === LocationConstants.GPS;
  }

  static getCollection = (data) => data.map((d) => new LocationModel(d));
}

export default LocationModel;
