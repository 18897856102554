import TokenManager from "@/commons/dataset/manager/TokenManager";
import TokenUserManager from "@/commons/dataset/manager/TokenUserManager";
import UserUtility from "@/commons/utilities/UserUtility";

/* import { cache } from './cache'
import { link } from './link'
import { offlineLink } from './link/offline' */
import { setContext } from "@apollo/client/link/context";
import { AuthenticatedOperations } from "../batch-http/authenticated-operations";
import { isAuthenticatedOperation } from "../batch-http/setup-fetch";

const authLink = setContext(async (request, { headers }) => {
  // get the authentication token from local storage if it exists
  const { operationName } = request;

  //const isAuthOperation = operationName === "createComment";
  const isAuthOperation = isAuthenticatedOperation(
    operationName as AuthenticatedOperations,
  );
  //const token = localStorage.getItem("token");
  if (isAuthOperation) {
    //const userData = UserUtility.getUserData();
    const userData = await TokenUserManager.getTokenUser(
      UserUtility.getUserData(),
    );

    if (userData) {
      return {
        headers: {
          ...headers,
          authorization: userData ? `Bearer ${userData}` : "",
        },
      };
    }
  } else {
    const token = await TokenManager.getToken();

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token.value}` : "",
      },
    };
  }
});

export { authLink };
