import { LeafletTypeConstants } from "@/commons/dataset/constants/LeafletConstats";
import { ids, slugs, icons } from "@/commons/ui/utils/fontawesomeNormalization";
import { TypePolicies } from "@apollo/client";
import { SlideToStoreCampaign } from "generated/graphql/types";
import { Offer } from "./Offer";
import { SeoInfo } from "./set-info";

const typePolicies: TypePolicies = {
  HomePageCategory: {
    fields: {
      fa_icon: {
        read(fa_icon, { readField }) {
          const id = readField("id") as string;
          const slug = readField("slug") as string;

          if (id in ids) return ids[id];
          if (slug in slugs) return slugs[slug];
          if (fa_icon in icons) return icons[fa_icon];

          return fa_icon;
        },
      },
    },
  },
  /* Store: {
    fields: {
      composedAddress: {
        read(_, { readField, cache }) {
          let address = readField("address");

          // TODO read city type
          const city = readField("city");
          const test = cache.identify(city);
          console.log("@@@ ### ### city", city, test);
          const cityName = city ? ` - ${city.name}` : "";
          address += cityName;

          // TODO
          // let provinceName = "";
          // if (!city.isProvince && city.province) {
          //   provinceName = ` (${city.province})`;
          // }

          // address += provinceName;
          return address;
        },
      },
      phone: {
        read(phone: string) {
          if (phone === null) return undefined;
          if (phone.length === 0) return undefined;
          return phone;
        },
      },
    },
  }, */
  Retailer: {
    fields: {
      existsLogo: {
        read(_, { readField }) {
          const logo = readField("logo");
          const logoWebP = readField("logo_webp");
          return logo !== null || logoWebP != null;
        },
      },
      formats: {
        read(_, { readField }) {
          let formats = [];
          const supportedFormats = readField(
            "logo_webp_supported_formats",
          ) as string[];
          const logoWebP = readField("logo_webp") as string;

          supportedFormats.forEach((f) => {
            formats.push({
              type: f,
              url: logoWebP.replace(":FORMAT", f),
            });
          });

          return formats;
        },
      },
    },
  },
  OfferContainer: {
    fields: {
      formats: {
        read(_, { readField }) {
          let formats = [];
          const supportedFormats = readField(
            "cover_webp_supported_formats",
          ) as string[];
          const logoWebP = readField("cover_webp") as string;

          supportedFormats.forEach((f) => {
            formats.push({
              type: f,
              url: logoWebP.replace(":FORMAT", f),
            });
          });

          return formats;
        },
      },
      isCoupon: {
        read(_, { readField }) {
          const type = readField("type") as string;
          return type === LeafletTypeConstants.COUPON;
        },
      },
      type: {
        read(type, { readField }) {
          const shopfully_id = readField("shopfully_id");
          const slide_to_store_campaign = readField("slide_to_store_campaign");

          let isStoreAnnouncement = false;
          const external_url: string = readField("external_url");
          if (external_url) {
            isStoreAnnouncement =
              external_url.indexOf("&ft=sa") !== -1 ||
              external_url.indexOf("?ft=sa") !== -1;
          }

          if (
            type == LeafletTypeConstants.CLICK_TO_STORE_LEAFLET &&
            shopfully_id !== null &&
            slide_to_store_campaign !== null
          ) {
            return LeafletTypeConstants.S2S;
          } else if (
            type == LeafletTypeConstants.CLICK_TO_STORE_LEAFLET &&
            isStoreAnnouncement
          ) {
            return LeafletTypeConstants.S2S;
          }

          return type;
        },
      },
      S2SCampaign: {
        read(_, { readField }) {
          const slideToStoreCampaign = readField(
            "slide_to_store_campaign",
          ) as SlideToStoreCampaign;
          if (!slideToStoreCampaign) return null;
          return {
            title: slideToStoreCampaign.title,
          };
        },
      },
      city: {
        // Da fare
        read() {
          return {
            name: null,
          };
        },
      },
    },
  },
  SeoInfo,
  Offer,
};

export { typePolicies };
