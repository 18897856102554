import Model from "./Model";
import StoreModel from "./StoreModel";
import OpeningHoursModel from "./OpeningHoursModel";
import ShoppingCenterInfoModel from "./ShoppingCenterInfoModel";
import CityModel from "./CityModel";
import CategoryModel from "./CategoryModel";
import SocialLinkModel from "./SocialLinkModel";

const setStores = (shoppingCenter) => {
  if (shoppingCenter.stores && shoppingCenter.stores.length > 0) {
    return StoreModel.getCollection(shoppingCenter.stores);
  }
  return [];
};

const setOpeningHours = (shoppingCenter) => {
  if (shoppingCenter.openingHours && shoppingCenter.openingHours.length > 0) {
    return OpeningHoursModel.getCollection(shoppingCenter.openingHours);
  }
  return [];
};

const setShoppingCenterInfo = (shoppingCenter) => {
  return shoppingCenter.shopping_center_info
    ? new ShoppingCenterInfoModel(shoppingCenter.shopping_center_info)
    : {};
};

const setCity = (shoppingCenter) => {
  return shoppingCenter.city ? new CityModel(shoppingCenter.city) : null;
};

const setSocialLinks = (shoppingCenter) => {
  return shoppingCenter.social_links
    ? SocialLinkModel.getCollection(shoppingCenter.social_links)
    : [];
};

const setRetailersTsggedCategory = (shoppingCenter) => {
  return shoppingCenter.retailers_tagged_category
    ? CategoryModel.getCollection(shoppingCenter.retailers_tagged_category)
    : [];
};

class ShoppingCenterModel extends Model {
  constructor(shoppingCenter = {}) {
    super(shoppingCenter);
    this._slug = shoppingCenter.slug;
    this._name = shoppingCenter.name;
    this._address = shoppingCenter.address;
    this._city = setCity(shoppingCenter);
    this._description = shoppingCenter.description;
    this._stores = setStores(shoppingCenter);
    this._open = shoppingCenter.open;
    this._openingHours = setOpeningHours(shoppingCenter);
    this._latitude = shoppingCenter.latitude;
    this._longitude = shoppingCenter.longitude;
    this._info = setShoppingCenterInfo(shoppingCenter);
    this._phone = shoppingCenter.phone;
    this._openingHoursText = shoppingCenter.opening_hours_text;
    this._website = shoppingCenter.website;
    this._email = shoppingCenter.email;
    this._socials = setSocialLinks(shoppingCenter);

    this._retailersTaggedCategory = setRetailersTsggedCategory(shoppingCenter);

    this._offersNumber = shoppingCenter.offers_count;
    this._storesNumber = shoppingCenter.stores_count;
    this._leafletsNumber = shoppingCenter.leaflets_count;
  }

  get slug() {
    return this._slug;
  }
  set slug(value) {
    this._slug = value;
  }

  get name() {
    return this._name;
  }
  set name(value) {
    this._name = value;
  }

  get address() {
    return this._address;
  }
  set address(value) {
    this._address = value;
  }

  get city() {
    return this._city;
  }
  set city(value) {
    this._city = value;
  }

  get description() {
    return this._description;
  }
  set description(value) {
    this._description = value;
  }

  get stores() {
    return this._stores;
  }
  set stores(value) {
    this._stores = value;
  }

  get open() {
    return this._open;
  }
  set open(value) {
    this._open = value;
  }

  get openingHours() {
    return this._openingHours;
  }
  set openingHours(value) {
    this._openingHours = value;
  }

  get latitude() {
    return this._latitude;
  }
  set latitude(value) {
    this._latitude = value;
  }

  get longitude() {
    return this._longitude;
  }
  set longitude(value) {
    this._longitude = value;
  }

  get info() {
    return this._info;
  }
  set info(value) {
    this._info = value;
  }

  get phone() {
    return this._phone;
  }
  set phone(value) {
    this._phone = value;
  }

  get openingHoursText() {
    return this._openingHoursText;
  }
  set openingHoursText(value) {
    this._openingHoursText = value;
  }

  get website() {
    return this._website;
  }
  set website(value) {
    this._website = value;
  }

  get email() {
    return this._email;
  }
  set email(value) {
    this._email = value;
  }

  get socials() {
    return this._socials;
  }
  set socials(value) {
    this._socialLinks = value;
  }

  get offersNumber() {
    return this._offersNumber;
  }
  set offersNumber(value) {
    this._offersNumber = value;
  }

  get storesNumber() {
    return this._storesNumber;
  }
  set storesNumber(value) {
    this._storesNumber = value;
  }

  get leafletsNumber() {
    return this._leafletsNumber;
  }
  set leafletsNumber(value) {
    this._leafletsNumber = value;
  }

  get retailersTaggedCategory() {
    return this._retailersTaggedCategory;
  }
  set retailersTaggedCategory(value) {
    this._retailersTaggedCategory = value;
  }

  get composedAddress() {
    let address = `${this._address}`;
    const cityName = this._city ? ` - ${this._city.name}` : "";
    address += cityName;
    const provinceName =
      this._city.isProvince === false && this._city.province
        ? ` (${this._city.province})`
        : "";
    address += provinceName;
    return address;
  }

  static getCollection(shoppingCenters) {
    return shoppingCenters.map(
      (shoppingCenter) => new ShoppingCenterModel(shoppingCenter),
    );
  }
}

export default ShoppingCenterModel;
