const LeafletStateConstants = {
  REJECTED: "rejected",
  CONFIRMED: "confirmed",
};

const LeafletTypeConstants = {
  LEAFLET: "Leaflet",
  CLICK_TO_STORE_LEAFLET: "ClickToStoreLeaflet",
  S2S: "S2S",
  COUPON: "Coupon",
};

const LeafletS2STypeConstants = {
  COLLECTION: "collection",
  CAROUSEL: "carousel",
};

export { LeafletStateConstants, LeafletTypeConstants, LeafletS2STypeConstants };
