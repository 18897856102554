export default {
  homePage: "/",
  passwordRecovery: "/recupera-password",
  homePageLocal: "/%{city}",
  leafletPreview: "/anteprima-volantini-nuovi",
  leafletPreviewLocal: "/%{city}/anteprima-volantini-nuovi",
  leaflet: "/volantino/%{retailer}/%{id}",
  leafletWithPage: "/volantino/%{retailer}/%{id}#p=%{page}",
  leafletWithOffer: "/volantino/%{retailer}/%{id}#p=%{page}&o=%{selectionArea}",
  leafletWithOfferId:
    "/volantino/%{retailer}/%{id}%{divisorUrl}p=%{page}&oid=%{offerId}",
  leafletWithOnlyOfferId:
    "/volantino/%{retailer}/%{id}%{divisorUrl}oid=%{offerId}",
  leafletWithCategoryLabel:
    "/volantino/%{retailer}/%{id}%{divisorUrl}category=%{category}",
  retailer: "/%{section}/%{slug}",
  retailerLocal: "/%{city}/%{section}/%{slug}",
  retailerAmp: "/%{section}/%{slug}/amp",
  retailerLocalAmp: "/%{city}/%{section}/%{slug}/amp",
  offer: "/offerte/%{slug}",
  offerLocal: "/%{city}/offerte/%{slug}",
  offerCategory: "/offerte/%{category}",
  offerCategoryLocal: "/%{city}/offerte/%{category}",
  offerRetailerCategory: "/offerte/%{retailer}/%{category}",
  offerPreferredLeaflets: "/offerte/volantini-preferiti",
  product: "/prodotti/%{category}/%{product}",
  search: "/search?q=%{key}",
  searchModel: "/search?q=%{key}&model=1",
  shoppingCenter: "/%{city}/%{section}/%{slug}",
  store: "/%{city}/%{section}/%{slug}",
  storeWithSinglePath: "/%{city}/negozio/%{slug}",
  storeRetailer: "/negozi/%{slug}",
  storeRetailerLocal: "/%{city}/negozi/%{slug}",
  nationalStore: "/negozi/%{slug}",
  shoppingList: "/preferenze/lista-spesa",
  shoppingListShare: "/preferenze/share_shopping_list/%{shareHash}",
  preferencesRegistration: "/preferenze/registrazione",
  preferencesStores: "/preferenze/negozi",
  preferencesProducts: "/preferenze/prodotti",
  preferencesCategories: "/preferenze/categorie",
  preferencesManage: "/preferenze/manage",
  localita: "/localita/%{firstLetter}",
  loyaltyCard: "/carta-fedelta/%{slug}",
  indexCategories: "/offerte-volantino/%{firstLetter}",
  indexCategoriesLocal: "/%{city}/offerte-volantino.%{firstLetter}",
  indexStores: "/negozi/%{firstLetter}",
  indexShoppingCenters: "/centro-commerciale/%{firstLetter}",
  requestPersonalData: "/preferenze/richiesta-dati-personali",
  mobileApp: "/mobile-app",
  retailers: "/negozi",
  who: "/chi-siamo",
  how: "/come-funziona",
  eco: "/eco",
  blog: "/blog",
  allBrands: "/marche",
  allCategories: "/offerte-volantino",
  allCategoriesLocal: "/%{city}/offerte-volantino",
  allLoyaltyCards: "/carta-fedelta",
  allCities: "/localita",
  allCitiesRanged: "/localita/%{range}",
  allOpenStores: "/negozi/aperti-oggi",
  allOpenStoresLocal: "/%{city}/negozi/aperti-oggi",
  allRetailers: "/negozi",
  allRetailersRanged: "/negozi/%{range}",
  allRetailersLocal: "/%{city}/negozi",
  allShoppingCenters: "/centro-commerciale",
  termsAndConditions: "/terms-and-conditions",
  privacy: "/privacy",
};
