import ImageModel from "./ImageModel";
import Model from "./Model";

const setLogo = (brand, ext = "") => {
  if (brand[`logo${ext}`]) {
    return new ImageModel(brand[`logo${ext}`]);
  }
  return null;
};

class BrandModel extends Model {
  constructor(brand = {}) {
    super(brand);
    this.name = brand.name;
    this.slug = brand.slug;
    this.url = brand.url;
    this.typename = brand.__typename;

    //--- logo ---
    this.logo = setLogo(brand);
    this.logoWebP = setLogo(brand, "_webp");
    this.logoWebPSupportedFormats = brand.logo_webp_supported_formats;
    this.localDescription = brand.local_description;
    this.nationalDescription = brand.national_description;
    //------------
  }

  static getCollection = (data) => data.map((d) => new BrandModel(d));
}

export default BrandModel;
