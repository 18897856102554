import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import { disableFragmentWarnings } from "graphql-tag";
import TokenModel from "@/commons/dataset/models/TokenModel";

class TokenMutations {
  constructor(urlClient) {
    this.urlClient = urlClient;
    disableFragmentWarnings();
  }

  getClient() {
    const paramsClient = {
      uri: this.urlClient,
      cache: new InMemoryCache(),
    };
    return new ApolloClient(paramsClient);
  }

  getFragments(fragments) {
    if (fragments) {
      return fragments
        .map((fragment) => fragment.gql.loc.source.body)
        .join(" ");
    }
    return "";
  }

  getMutation(name, mutation, data, fragments = null, variables = {}) {
    fragments = this.getFragments(fragments);
    data = data != "" ? `{ ${data} }` : "";
    const gqlMutation = `mutation ${name}{ ${mutation}${data} } ${fragments}`;
    const paramsMutation = {
      mutation: gql`
        ${gqlMutation}
      `,
      variables: variables,
    };

    return paramsMutation;
  }

  execMutation(name, mutation, data = null, fragments = null, variables = {}) {
    return new Promise(async (resolve, reject) => {
      const paramsMutation = this.getMutation(
        name,
        mutation,
        data,
        fragments,
        variables,
      );

      try {
        const client = this.getClient();
        const resultMutation = await client.mutate(paramsMutation);
        if (resultMutation) {
          const parseResultMutation = this.getResult(resultMutation);
          resolve(parseResultMutation);
        } else {
          reject(`PQ ERROR: ${name} result not found`);
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  getResult(result) {
    if (result.data && result.data.token) {
      const dataResult = result.data.token;
      return new TokenModel(dataResult);
    }
    return null;
  }

  get(params) {
    const { apiKey, device } = params;
    const name = "Token_get($apiKey:String!, $device:allowedAppEnum)";
    const data = `jwt_token jwt_token_expires`;
    const mutation = `token:AppAuth(api_key: $apiKey,device: $device)`;
    const variables = { apiKey, device };
    return this.execMutation(name, mutation, data, [], variables);
  }

  renewal(token) {
    const name = "Token_renewal($oldToken:String!)";
    const data = `jwt_token jwt_token_expires`;
    const mutation = `token:JwtAppRenewal(token:$oldToken)`;
    const variables = { oldToken: token };
    return this.execMutation(name, mutation, data, [], variables);
  }
}

export default TokenMutations;
