import CityModel from "./CityModel";
import ImageModel from "./ImageModel";
import Model from "./Model";
import OpeningHoursModel from "./OpeningHoursModel";
import RetailerModel from "./RetailerModel";
// import ShoppingCenterModel from './ShoppingCenterModel';

const setDistance = (store) => {
  if (typeof store.distance === "number") {
    return store.distance;
  } else if (typeof store.distance === "string") {
    return parseFloat(store.distance);
  } else {
    return null;
  }
};

const setPhone = (store) => {
  if (store.phone === null) return undefined;
  if (typeof store.phone === "string" && store.phone.length === 0)
    return undefined;
  return store.phone;
};

const setImage = (store) => {
  return store.image ? new ImageModel(store.image) : null;
};

const setOpeningHours = (store) => {
  return store.openingHours
    ? OpeningHoursModel.getCollection(store.openingHours)
    : null;
};

const setRetailer = (store) => {
  return store.retailer ? new RetailerModel(store.retailer) : null;
};

const setCity = (store) => {
  return store.city ? new CityModel(store.city) : null;
};

const setShoppingCenter = (store) => {
  // return store.shopping_center ? new ShoppingCenterModel(store.shopping_center) : null;
};

class StoreModel extends Model {
  constructor(store = {}) {
    super(store);
    this.shopfullyId = store.shopfully_id;
    this.name = store.name;
    this.slug = store.slug;
    this.image = setImage(store);
    this.distance = setDistance(store);
    this.address = store.address;
    this.callnow = store.callnow;
    this.phone = setPhone(store);
    this.extraInfos = store.extra_infos;
    this.latitude = parseFloat(store.latitude);
    this.longitude = parseFloat(store.longitude);
    this.retailer = setRetailer(store);
    this.open = store.open;
    this.openingHours = setOpeningHours(store);
    this.openingHoursText = store.opening_hours_text;

    this.city = setCity(store);
    this.pin = store.pin;
    this.has_confirmed_leaflets = store.has_confirmed_leaflets;
    this.floor = store.floor;

    this.shoppingCenter = setShoppingCenter(store);
  }

  get composedAddress() {
    let address = `${this.address}`;
    const cityName = this.city ? ` - ${this.city.name}` : "";
    address += cityName;

    let provinceName = "";
    if (!this.city?.isProvince && this.city?.province) {
      provinceName = ` (${this.city.province})`;
    }

    address += provinceName;
    return address;
  }

  get composedName() {
    if (this.retailer.id === undefined) return this.name;
    const retailerName = this.retailer.name || "";
    if (
      this.name &&
      this.name.toLowerCase().indexOf(retailerName.toLowerCase()) >= 0
    )
      return this.name;
    return `${retailerName != "" ? `${retailerName} - ` : ""} ${this.name}`;
  }

  static getCollection(stores = []) {
    return stores.map((store) => new StoreModel(store));
  }
}

export default StoreModel;
