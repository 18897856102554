import { getCookie } from "cookies-next";
import GTag from "./GTag";

class OneTrustUtils {
  static isChoose() {
    let isChoose = false;
    const preferences = OneTrustUtils.getPreferencesUser(OneTrustUtils.KEY.ADS);
    if (preferences.purposes) {
      isChoose = true;
    } else {
      isChoose = false;
    }
    OneTrustUtils.isUserSetPreferences = isChoose;
    return isChoose;
  }

  static getPreferencesUser(key = OneTrustUtils.KEY.ADS) {
    if (
      !OneTrustUtils.preferencesUser ||
      Object.keys(OneTrustUtils.preferencesUser).length === 0
    ) {
      const cookieConsentClosed = getCookie("OptanonAlertBoxClosed");
      if (cookieConsentClosed) {
        const cookieConsent = getCookie("OptanonConsent");

        const cookieConsentParsed = {};
        cookieConsent.split("&").forEach((pair) => {
          const [key, value] = pair.split("=");
          cookieConsentParsed[key] = decodeURIComponent(value);
        });

        let purposes = {};
        if (cookieConsentParsed.groups) {
          purposes = cookieConsentParsed.groups
            .split(",")
            .reduce((obj, item) => {
              const key = item.split(":")[0];
              const value = item.split(":")[1] === "1";
              return {
                ...obj,
                [key]: value,
              };
            }, {});
        }

        const preferences = { purposes };
        OneTrustUtils.setPreferencesUser(preferences);

        return purposes[key];
      } else {
        return false;
      }
    }

    console.log(
      `@@@### preferencesUser[${key}]`,
      OneTrustUtils.preferencesUser[key],
    );

    return OneTrustUtils.preferencesUser[key];
  }

  static setPreferencesUser(preferences) {
    console.log("@@@### setPreferencesUser", preferences);
    let purposes = {},
      isChoose = false;

    if (preferences.purposes) {
      purposes = preferences.purposes;
      isChoose = true;
    } else {
      purposes[OneTrustUtils.KEY.NEEDED] = true;
      purposes[OneTrustUtils.KEY.IMPROVED_EXPERIENCE] = false;
      purposes[OneTrustUtils.KEY.MEASUREMENT] = false;
      purposes[OneTrustUtils.KEY.ADS] = false;
      isChoose = false;
    }

    OneTrustUtils.preferencesUser = purposes;

    OneTrustUtils.updateConsentMode();

    OneTrustUtils.isUserSetPreferences = isChoose;
  }

  static updateConsentMode() {
    if (
      !OneTrustUtils.preferencesUser ||
      Object.keys(OneTrustUtils.preferencesUser).length === 0
    ) {
      return;
    }

    const purposes = OneTrustUtils.preferencesUser;

    const consents = {};

    // NEEDED "C0001" (Strictly Necessary)
    consents[GTag.CONSENT_TYPE.ANALYTICS_STORAGE] = true;

    // ADS "C0004" (Targeted advertising)
    consents[GTag.CONSENT_TYPE.AD_STORAGE] = !!purposes[OneTrustUtils.KEY.ADS];
    consents[GTag.CONSENT_TYPE.AD_USER_DATA] =
      !!purposes[OneTrustUtils.KEY.ADS];
    consents[GTag.CONSENT_TYPE.AD_PERSONALIZATION] =
      !!purposes[OneTrustUtils.KEY.ADS];

    // NOT USED
    consents[GTag.CONSENT_TYPE.FUNCTIONALITY_STORAGE] = false;
    consents[GTag.CONSENT_TYPE.SECURITY_STORAGE] = false;
    consents[GTag.CONSENT_TYPE.PERSONALIZATION_STORAGE] = false;

    GTag.updateConsentMode(consents);
  }
}

OneTrustUtils.KEY = {
  NEEDED: "C0001", // Strictly Necessary
  MEASUREMENT: "C0002", // Performance
  IMPROVED_EXPERIENCE: "C0003", // Functionality
  ADS: "C0004", // Targeted advertising
};

OneTrustUtils.preferencesUser = {};
OneTrustUtils.isUserSetPreferences = false;

if (typeof window !== "undefined") {
  window.OneTrustUtils = OneTrustUtils;
}

export default OneTrustUtils;
