import ContextKeys from "@/commons/keys/ContextKeys";

//carica dal globalThis server al globalThis client
const usePQGlobalThis = (props) => {
  const { globalThisServer } = props;

  if (typeof globalThis !== "undefined") {
    if (globalThisServer) {
      Object.values(ContextKeys).forEach((key) => {
        globalThis[key] = globalThisServer[key];
      });
    } else {
      console.log(
        "### >>>>>>>>> usePQGlobalThis -globalThisServer",
        globalThisServer,
      );
      console.log("### >>>>>>>>> usePQGlobalThis -ContextKeys", ContextKeys);
      throw new Error(
        "PQ: aggiungere props globalThisServer alla funzione getInitialProps di pagina",
      );
    }
  }
};

const useSelectorPQGlobalThis = () => {
  const dataSelect = {};
  if (typeof globalThis !== "undefined") {
    Object.values(ContextKeys).forEach((key) => {
      dataSelect[key] = globalThis[key];
    });
  } else {
    console.log(
      "### >>>>>>>>> useSelectorPQGlobalThis - ContextKeys",
      ContextKeys,
    );
  }
  return dataSelect;
};

export { usePQGlobalThis, useSelectorPQGlobalThis };
