export default {
  socialLogin: {
    facebook: false,
    google: false,
  },
  facebook: {
    appID: "1408013266189547",
    scope: "email, user_location",
    pixelId: "",
  },
};
