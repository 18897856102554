import Model from "./Model";
import CategoryModel from "./CategoryModel";

class HPCModel extends Model {
  constructor(hpc = {}) {
    super(hpc);
    this.name = hpc.name;
    this.slug = hpc.slug;
    this.url = hpc.url;
    this.icon = hpc.fa_icon;
    this.color = hpc.fa_color;
    this.category = hpc.category ? new CategoryModel(hpc.category) : null;
  }

  static getCollection = (data) => data.map((d) => new HPCModel(d));
}

export default HPCModel;
