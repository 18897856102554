import { useSelectorPQGlobalThis } from "@/commons/hooks/usePQGlobalThis";
import PqConsole from "@/commons/utilities/PqConsole";
import OneTrustUtils from "./OneTrustUtils";

class GTag {
  static initialized = false;

  static rStateRendertron = null;
  static rStateAppRender = null;
  static googleAnalyticsData = null;

  /* global dataLayer */
  static init(rStateRendertron, rStateAppRender, googleAnalyticsData) {
    GTag.rStateAppRender = rStateAppRender;
    GTag.googleAnalyticsData = googleAnalyticsData;

    if (typeof document == undefined) return;

    return new Promise(async (resolve, reject) => {
      if (rStateRendertron) return;
      try {
        const result = await GTag.inject([
          pqConfs?.gAnalytics.general.id,
          pqConfs?.gAnalytics.general.id4,
        ]);
        if (result) {
          const { pqConfs } = useSelectorPQGlobalThis();
          GTag.initialized = true;
          window.dataLayer = window.dataLayer || []; /*global */
          function gtag() {
            dataLayer.push(arguments);
          }
          window.gtag = gtag;

          // Consent Mode: Set the Default Tag Behavior
          gtag("consent", GTag.CONSENT_COMMAND.DEFAULT, {
            [GTag.CONSENT_TYPE.ANALYTICS_STORAGE]: "granted",

            [GTag.CONSENT_TYPE.AD_STORAGE]: "denied",
            [GTag.CONSENT_TYPE.AD_USER_DATA]: "denied",
            [GTag.CONSENT_TYPE.AD_PERSONALIZATION]: "denied",

            [GTag.CONSENT_TYPE.FUNCTIONALITY_STORAGE]: "denied",
            [GTag.CONSENT_TYPE.SECURITY_STORAGE]: "denied",
            [GTag.CONSENT_TYPE.PERSONALIZATION_STORAGE]: "denied",

            [GTag.CONSENT_TYPE.WAIT_FOR_UPDATE]: 2000,
          });
          console.log("@@@#-- Gtag Consent Default: All Denied and wait 500ms");

          OneTrustUtils.updateConsentMode();

          gtag("js", new Date());

          // Add GA properties
          let objectGA4 = {};
          if (GTag.googleAnalyticsData?.dimensions) {
            let objectGA = {};
            Object.keys(GTag.googleAnalyticsData?.dimensions).forEach((k) => {
              objectGA[k] = GTag.googleAnalyticsData?.dimensions[k];
              let k4;
              if (k == "dimension1") {
                k4 = "template_param";
              } else if (k == "dimension2") {
                k4 = "firstcontent_param";
              }
              objectGA4[k4] = GTag.googleAnalyticsData?.dimensions[k];
            });
            gtag("set", objectGA);
          }

          gtag("config", pqConfs.gAnalytics.general.id);
          gtag("config", pqConfs.gAnalytics.general.id4, objectGA4);

          console.log("@@@#-- GA4 config done");

          resolve(true);
        } else {
          const error = new Error("script GTAG not load");
          PqConsole.error("script GTAG not load", error);
          reject(error);
        }
      } catch (error) {
        PqConsole.error("GTag.init()", error);
        reject(error);
      }
    });
  }

  static inject(listGtagIds) {
    return new Promise(async (resolve, reject) => {
      const listPromise = listGtagIds.map((id) => {
        return new Promise((resolve, reject) => {
          try {
            const refScript = document.querySelector(
              `script[data-type="GTAG-${id}"]`,
            );
            if (!refScript) {
              const { pqConfs } = useSelectorPQGlobalThis();
              const script = document.createElement("script");
              script.dataset.type = `GTAG-${id}`;
              script.type = "text/javascript";
              script.async = true;
              script.onload = () => {
                resolve(true);
              };
              script.onerror = (error) => {
                reject(error);
              };
              script.src = `${GTag.script}${id}`;
              document.getElementsByTagName("head")[0].appendChild(script);
            } else {
              resolve(true);
            }
          } catch (error) {
            reject(error);
          }
        });
      });

      const result = await Promise.all(listPromise);
      let resultEvery = false;
      if (result) {
        resultEvery = result.every((element) => element === true);
      }
      if (resultEvery) {
        resolve(resultEvery);
      } else {
        const error = new Error("Load script GTAG not work");
        PqConsole.error("Load script GTAG not work", error);
        reject(error);
      }
    });
  }

  static sendDownloadApp() {}

  static sendPageView(path, href, fields = {}, destination = null) {
    if (typeof document == undefined || !GTag.initialized) return;
    path = path || document.location.pathname;
    href = href || document.location.href;

    const { pqConfs } = useSelectorPQGlobalThis();

    const dimensions = {};
    let index = 1;
    if (["au", "it"].includes(pqConfs.country)) {
      dimensions["template"] = GTag.rStateAppRender;
      dimensions[`dimension${index}`] = GTag.rStateAppRender;
      index++;
    }
    Object.keys(fields).forEach((k) => {
      dimensions[`dimension${index}`] = fields[k];
      index++;
    });

    let params = {
      ...dimensions,
      page_path: path,
      page_title: document.title,
      page_location: href,
    };
    if (destination) params = { ...params, send_to: destination };

    window.gtag("event", "page_view", params);
  }

  static checkParamsSendEvent(category, action, label, value) {
    let error = false;
    if (category !== undefined && typeof category !== "string") {
      PqConsole.warn(
        "GTag.sendEvent(category) - The param should be a string.",
      );
      error = true;
    }
    if (action !== undefined && typeof action !== "string") {
      PqConsole.warn("GTag.sendEvent(action) - The param should be a string.");
      error = true;
    }
    if (label !== undefined && typeof label !== "string") {
      PqConsole.warn("GTag.sendEvent(label) - The param should be a string.");
      error = true;
    }
    if (value !== undefined && typeof value !== "number") {
      PqConsole.warn("GTag.sendEvent(value) - The param should be a number.");
      error = true;
    }

    return !error;
  }

  static sendEvent(category, action, label, value, destination) {
    const checkParamsSendEvent = GTag.checkParamsSendEvent(
      category,
      action,
      label,
      value,
    );
    if (
      typeof document == undefined ||
      !GTag.initialized ||
      !checkParamsSendEvent
    )
      return;

    let params = {
      event_category: category,
      event_label: label,
      value: value,
    };
    if (destination) params = { ...params, send_to: destination };

    window.gtag("event", action, params);
  }

  /**
   * Updates the consent mode for the specified consent type.
   *
   * @param {string} consentType - use GTag.CONSENT_TYPE to specify the type of consent to update.
   * @param {boolean} isGranted - Indicates whether the consent is granted or not.
   */
  static updateConsentMode(consents) {
    if (!GTag.initialized) return;

    const consentParam = {};
    for (const consentKey of Object.keys(consents)) {
      consentParam[consentKey] = consents[consentKey]
        ? GTag.CONSENT_VALUE.GRANTED
        : GTag.CONSENT_VALUE.DENIED;
    }

    window.gtag("consent", GTag.CONSENT_COMMAND.UPDATE, consentParam);

    console.log("@@@#-- Gtag Consent Update:", consentParam);
  }
}

GTag.script = "https://www.googletagmanager.com/gtag/js?id=";

GTag.CATEGORY = {
  LEAFLET: "leaflet",
  PROFILES: "profiles",
  SHOPPING_LIST: "shopping_list",
  OFFERS: "offers",
  MAP: "map",
  CATEGORIES: "categories",
  LOCALIZEME: "localizeme",
  STORE: "store",
  DOWNLOAD_APP: "download_app",
  ONETRUST: "onetrust",
};

GTag.ACTION = {
  CONVERSION: "conversion",
  INITIAL_LAYOUT: "initial_layout",
  CHANGE_LAYOUT: "change_layout",
  SEARCH: "search",
  SEARCHED: "searched",
  SEARCH_CLICKED: "search_clicked",
  SUGGESTION_CLICKED: "suggestion_clicked",
  FAVOURITES: "favourites",
  OPEN_MAP: "open_map",
  OPEN_SHOPPINGLIST: "open_shoppinglist",
  ADD_OFFER: "add_offer",
  ADD_CATEGORY: "add_category",
  ADD_BRAND: "add_brand",
  ADD_PRODUCT: "add_product",
  ADD_TEXT: "add_text",
  OPEN_FROM_LEAFLET: "open_from_leaflet",
  ALERT_FROM_LISTING: "alert_from_listing",
  ALERT_FROM_LEAFLET: "alert_from_leaflet",
  ALERT: "alert",
  OPEN_PARTNER_LINK: "open_partner_link",
  OPEN_STANDALONE_FROM_SERP: "open_standalone_from_serp",
  FILTER: "filter",
  REGISTER: "register",
  SORTING: "sorting",
  SIGNIN: "signin",
  DENIED: "denied",
  PUSH: "push",
  SIGNUP: "signup",
  WHERE_TO_BUY: "where_to_buy",
  DOWNLOAD_APP: "download_app",
  IOS: "ios",
  ANDROID: "android",
  ADV_PAGE_CLICK: "adv_page_click",
  ADV_PAGE: "adv_page",
  COOKIE_ACCEPT: "accept",
  COOKIE_REJECT: "reject",
  COOKIE_PREFERENCES: "preferences",
};

GTag.CONSENT_COMMAND = {
  DEFAULT: "default",
  UPDATE: "update",
};

GTag.CONSENT_TYPE = {
  // Consent to store information, such as cookies, related to advertising.
  AD_STORAGE: "ad_storage",
  // Sets the consent for sending user data related to advertising.
  AD_USER_DATA: "ad_user_data",
  // Sets the consent for personalized advertising.
  AD_PERSONALIZATION: "ad_personalization",
  // Consent to store information, such as cookies, related to analytics
  //   (such as the duration of the visit).
  ANALYTICS_STORAGE: "analytics_storage",
  // Consent to store information that support the functionality of the website
  //   or app (such as the language settings).
  FUNCTIONALITY_STORAGE: "functionality_storage",
  // Consent to store information related to personalization (such as video recommendations).
  PERSONALIZATION_STORAGE: "personalization_storage",
  // Consents to store information related to security, such as authentication, prevention
  //   of fraudulent activities, and other security measures.
  SECURITY_STORAGE: "security_storage",
  // Wait time before sending data.
  WAIT_FOR_UPDATE: "wait_for_update",
};

GTag.CONSENT_VALUE = {
  GRANTED: "granted",
  DENIED: "denied",
};

export default GTag;
