import { useSelectorPQGlobalThis } from "@/commons/hooks/usePQGlobalThis";
import { DateTime } from "luxon";
import sha1 from "crypto-js/sha1";
import axios from "axios";
import { LocationConstants } from "@/commons/dataset/constants/LocationConstants";
import CityQueries from "@/commons/dataset/queries/CityQueries";
import LocationModel from "@/commons/dataset/models/LocationModel";

class Geolocation {
  generateRandomString(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  generateToken() {
    const { pqConfs } = useSelectorPQGlobalThis();
    const date = DateTime.now()
      .setZone("Europe/Rome")
      .toFormat("y-MM-dd HH:mm");
    const token =
      this.generateRandomString(10) +
      sha1(`${date}${pqConfs.geolocation.apiKey}`) +
      this.generateRandomString(10);
    return token;
  }

  async exec(service, params, returnJson = false) {
    const header = {
      headers: {
        Authorization: `Bearer ${this.generateToken()}`,
      },
    };
    params = { ...params, country: pqConfs.country };

    const result = await axios.post(
      `${pqConfs.geolocation.domain}${service}`,
      params,
      header,
    );
    if (result?.data) {
      if (returnJson) return result.data;
      else return new LocationModel(result.data);
    } else {
      throw new Error("PQ ERROR: location not found");
    }
  }

  async bySlug(slug, returnJson = false) {
    const service = "locate-by-slug";
    const params = { slug };
    const location = await this.exec(service, params, returnJson);
    return await this.completeLocationData(location, returnJson);
  }

  async byIPUser(ip, returnJson = false) {
    const service = "locate-by-ip";
    const params = { ip };
    const location = await this.exec(service, params, returnJson);
    return await this.completeLocationData(location, returnJson);
  }

  async byUser(locationByUser, returnJson = false) {
    const service = "locate-by-user";
    const params = {
      user: { ...locationByUser, type: LocationConstants.USER },
    };
    const location = await this.exec(service, params, returnJson);
    return await this.completeLocationData(location, returnJson);
  }

  async byLatLon(latitude, longitude, address = null, returnJson = false) {
    const service = "locate-by-position";
    let params = { position: { latitude, longitude } };

    if (address) {
      params = {
        ...params,
        address: {
          short_address: address.short_address,
          full_address: address.full_address,
          zip_code: address.zip_code,
        },
      };
    }

    const location = await this.exec(service, params, returnJson);
    return await this.completeLocationData(location, returnJson);
  }

  async byCookie(cookie, returnJson = false) {
    const service = "locate-by-cookie";
    const params = { cookie };
    const location = await this.exec(service, params, returnJson);
    return await this.completeLocationData(location, returnJson);
  }

  async default(returnJson = false) {
    const service = "";
    const params = {};
    const location = await this.exec(service, params, returnJson);
    return await this.completeLocationData(location, returnJson);
  }

  async completeLocationData(location, returnJson) {
    const cityQueries = new CityQueries();
    const city = await cityQueries.storeCount({
      citySlug: location.slug,
      returnJson,
    });

    if (city) {
      location = { ...location, id: city.id, city };
    }
    return location;
  }

  byUser(userData, returnJson = false) {
    const service = "locate-by-user";
    const params = { user: { ...userData, type: LocationConstants.USER } };
    return this.exec(service, params, returnJson);
  }
}

export default Geolocation;
