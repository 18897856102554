import Mutations from "./Mutations";
import DeviceModel from "@/commons/dataset/models/DeviceModel";

class DeviceUserMutations extends Mutations {
  constructor(urlClient) {
    super(urlClient);
  }

  getResult(result, returnJson = false) {
    if (result.data) {
      const dataResult = result.data.deviceUser;
      if (returnJson) {
        return dataResult;
      } else {
        return new DeviceModel(dataResult);
      }
    }
    return null;
  }

  get(params) {
    const defaults = {
      deviceType: null,
      platform: null,
      appVersion: null,
      lat: null,
      lon: null,
      returnJson: false,
    };

    params = { ...defaults, ...params };
    const { deviceType, platform, appVersion, lat, lon, returnJson } = params;

    const name = `DeviceUserMutations_get(
      $deviceType: String!,
      $platform: String!,
      $appVersion: String!,
      $location: String
    )`;

    const data = `id device_type platform app_version latitude longitude city`;

    const mutation = `deviceUser:CreateOrGetDevice (
      device_type: $deviceType
      platform: $platform
      app_version: $appVersion
      location: $location
    )`;

    const location = lat && lon ? `${lat},${lon}` : null;
    const variables = { deviceType, platform, appVersion, location };

    return this.execMutation(name, mutation, data, [], variables, returnJson);
  }

  update(params) {
    const defaults = {
      deviceId: null,
      firebaseToken: null,
      deviceType: null,
      platform: null,
      appVersion: null,
      lat: null,
      lon: null,
      returnJson: false,
    };

    params = { ...defaults, ...params };
    const {
      deviceId,
      firebaseToken,
      deviceType,
      platform,
      appVersion,
      lat,
      lon,
      returnJson,
    } = params;

    const name = `DeviceUserMutations_update(
      $deviceId: Int,
      $firebaseToken: String,
      $deviceType: String!,
      $platform: String!,
      $appVersion: String!,
      $location: String
    )`;

    const data = `id device_type platform app_version latitude longitude city`;

    const mutation = `deviceUser:CreateOrGetDevice (
      device_id: $deviceId
      firebase_token: $firebaseToken
      device_type: $deviceType
      platform: $platform
      app_version: $appVersion
      location: $location
    )`;

    const location = lat && lon ? `${lat},${lon}` : null;
    const variables = {
      deviceId: parseInt(deviceId),
      firebaseToken,
      deviceType,
      platform,
      appVersion,
      location,
    };

    return this.execMutation(name, mutation, data, [], variables, returnJson);
  }
}

export default DeviceUserMutations;
