import routes from "./routes.js";
import footer from "./footer.js";
import ads from "./ads.js";

const config = {
  site: "AiciPromotii",
  country: "ro",
  locale: "ro",
  language: "ro-RO",
  env: "production",
  maxDistance: 30000,
  settings: { disableImagesLazyLoad: false },
  frontendDomain: "https://www.aicipromotii.ro",
  dataAsset: {
    url: "https://data.aicipromotii.ro",
  },
  threshold: {
    store: {
      small: 6,
      medium: 25,
    },
  },
  gql: {
    device: "PROMOQUI_WEB",
    apiKey: "32d985869c12f71f1421eb47bda1b5543a337c13da8397775b677832e49698fa",
    domainGql: "https://apiv3.aicipromotii.ro/graphql?",
    domainGqlAppauth: "https://apiv3.aicipromotii.ro/graphql/appauth",
    domainGqlAuthenticated:
      "https://apiv3.aicipromotii.ro/graphql/authenticated",
  },
  geolocation: {
    apiKey: "32d985869c12f71f1421eb47bda1b5543a337c13da8397775b677832e49698fa",
    domain: "https://api.aicipromotii.ro/geolocation/",
  },
  socialLinks: {
    facebook: "https://www.facebook.com/AiciPromotii",
    twitter: "https://www.twitter.com/promoqui",
    googlePlus: "https://plus.google.com/+PromoquiItSpa",
    pinterest: "https://it.pinterest.com/promoqui/",
  },
  footer: footer,
  apple: {
    clientId: "",
  },
  iubenda: {
    siteId: 217068,
    cookiePolicyId: 363827,
    cookieId: "_iub_cs-363827",
    publicKey: "IyII26vhFcIMf5zq75GcXYjrfGMNOLOH",
    cookiePolicyURL:
      "https://www.iubenda.com/privacy-policy/363827/cookie-policy",
    localConsentDomain: "www.aicipromotii.ro",
  },
  google: {
    googleMaps: "AIzaSyBtalFrzwZsFhlU3PIf00vAIOv31GpmiaQ",
    googleClientID:
      "691043485490-0bkie4tpavs054h3t2uk7ed67sfu2uaf.apps.googleusercontent.com",
  },
  sentry: {
    enabled: false,
    dsn: "https://172e7784148b4ad9bb1c721a9c03ad99@o150860.ingest.sentry.io/6726561",
  },
  appStore: {
    bannerApp: true,
    apple:
      "https://itunes.apple.com/ro/app/ilikesales-weekly-ads-local/id466421194",
    android:
      "https://play.google.com/store/apps/details?id=it.promoqui.android&hl=ro",
    windows:
      "http://www.windowsphone.com/ro-ro/store/app/aicipromotii/e8a4ab90-792a-4536-8efe-c40eb5ec014f",
  },
  gAnalytics: {
    gtmId: "GTM-W3F59J8",
    general: {
      id: "UA-25234055-8",
      label: "",
    },
    trackingPage: {
      id: "",
      label: "leafletViewerTracker",
    },
  },
  shopfully: {
    api: {
      openStreetMap: "https://pq-maps.shopfully.cloud/styles/klokantech-basic",
    },
  },
  enableJSONLD: {
    retailer: true,
  },
};

export default { ...config, routes: routes, ads: ads };
