const CookieKeys = {
  SUGGESTIONS: "suggestions",
  DEVICE_USER: "pqDeviceUser",
  FIREBASE_COOKIE_ID: "firebase_token",
  SHOPPING_LIST_MERGED: "pqShoppingListMerged",
  IUBENDA_REJECT: "pqIubendaReject",
  LOCATION: "location",
  LOCATION2: "location2",
  USER: "PQUSER",
  LOCATION_SNACKBAR: "LOCATIONSNACKBAR",
  TIMES_VISITED: "TIMESVISITED",
  DOWNLOAD_APP: "DOWNLOADAPP",
  USERCREDENTIALS: "userCredentials",
};

export default CookieKeys;
