const ids = {
  "13025": "fa-graduation-cap",
  "15740": "fa-ghost",
};

const icons = {
  "fa-car": "fa-car-alt",
  "fa-phone": "fa-phone-alt",
  "fa-globe": "fa-globe-africa",
  "fa-child": "fa-baby",
  "fa-futbol-o": "fa-futbol",
  "fa-cutlery": "fa-utensils",
  "fa-dollar": "fa-dollar-sign",
  "fa-eur": "fa-euro-sign",
  "fa-glass": "fa-glass-martini",
  "fa-reddit-alien": "fa-theater-masks",
  "fa-bell-o": "fa-egg",
};

const slugs = {
  discount: "fa-piggy-bank",
  "cura-casa-persona": "fa-mitten",
  "natale-2020": "fa-tree",
};

export { ids, icons, slugs };
