import Navigator from "@/commons/utilities/Navigator";

class LocalizeMe {
  static localize(rStateHeaders) {
    return new Promise((resolve, reject) => {
      const geolocation = window.navigator.geolocation;
      if (!geolocation)
        reject(new Error("LocalizeMe - Geolocation API not supported!"));

      const options = {
        enableHighAccuracy:
          Navigator.isMobile(rStateHeaders) ||
          Navigator.isTablet(rStateHeaders),
      };

      geolocation.getCurrentPosition(
        (position) => resolve(position),
        (error) => reject(error),
        options,
      );
    });
  }
}

export default LocalizeMe;
