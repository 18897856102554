import { ImageSizeConstants } from "@/commons/dataset/constants/ImageSizeConstants";

class ImageModel {
  constructor(imagesPath) {
    this.imgFormat = imagesPath;
  }

  get thumb() {
    return this.getFormat(ImageSizeConstants.THUMB);
  }

  get preview() {
    return this.getFormat(ImageSizeConstants.PREVIEW);
  }

  get medium() {
    return this.getFormat(ImageSizeConstants.MEDIUM);
  }

  get large() {
    return this.getFormat(ImageSizeConstants.LARGE);
  }

  get mediumLarge() {
    return this.getFormat(ImageSizeConstants.MEDIUM_LARGE);
  }

  get pagePreview() {
    return this.getFormat(ImageSizeConstants.PAGE_PREVIEW);
  }

  get original() {
    return this.getFormat(ImageSizeConstants.ORIGINAL);
  }

  get small() {
    return this.getFormat(ImageSizeConstants.SMALL);
  }

  get normal() {
    return this.getFormat(ImageSizeConstants.NORMAL);
  }

  get pin() {
    return this.getFormat(ImageSizeConstants.PIN);
  }

  get boxInfo() {
    return this.getFormat(ImageSizeConstants.BOXINFO);
  }

  getFormat(format) {
    return this.imgFormat?.replace(":FORMAT", format);
  }
}

export default ImageModel;
