import Model from "./Model";
class TokenModel extends Model {
  constructor(token = {}) {
    super(token);
    this.value = token.jwt_token || token.value;
    this.expires = token.jwt_token_expires || token.expires;
  }

  get isExpired() {
    var now = new Date();
    var expires = new Date(this.expires);
    return expires > now;
  }
}

export default TokenModel;
