import rdxUserEvents from "@/commons/redux/events/rdxUserEvents";
import {
  createAction,
  createAsyncThunk,
  PrepareAction,
} from "@reduxjs/toolkit";
import Geolocation from "@/commons/dataset/services/Geolocation";

import UserUtility from "@/commons/utilities/UserUtility";
import LocalizeMe from "@/commons/dataset/services/LocalizeMe";
import GTag from "@/commons/tracking/GTag";
import {
  RegisterUserDocument,
  RegisterUserMutation,
  SocialProviderEnum,
  User,
  UserLoginDocument,
  UserLoginMutation,
  UserSocialLoginDocument,
  UserSocialLoginMutation,
} from "generated/graphql/types";
import { client } from "apollo";
import { LocationModel } from "@/commons/dataset/models";
import DeviceIdUtility from "@/commons/utilities/DeviceIdUtility";
import UserModel from "@/commons/dataset/models/UserModel";
import { ccontextSelector } from "../selectors/ccontext";
import { locationSelector } from "../selectors/location";
import { RootState } from "../reducers/stores";
import {
  MutationOptions,
  OperationVariables,
  DefaultContext,
  ApolloCache,
} from "@apollo/client";

const rdxUserInit = createAction(
  rdxUserEvents.INIT,
  function prepare(userData: UserModel) {
    return {
      payload: userData,
    };
  },
);

const setShowLoginDialog = createAction(
  rdxUserEvents.SET_SHOW_LOGIN_DIALOG,
  function prepare(value: boolean) {
    return {
      payload: value,
    };
  },
);

const rdxUserLogout = createAction(rdxUserEvents.LOGOUT);

const rdxUserUpdate = createAction<PrepareAction<UserModel>, any>(
  rdxUserEvents.UPDATE,
  function prepare(userData: UserModel) {
    return {
      payload: userData,
    };
  },
);

const rdxUserSignup = createAsyncThunk(
  rdxUserEvents.LOGOUT,
  // Declare the type your function argument here:
  async (variables: OperationVariables, { rejectWithValue }) => {
    return null;

    try {
      /* 
        --- Questo lo lascio per testare la registrazione con un profile fake ---
        const userData = {
        id: 129610,
        username: "tes-956540",
        name: null,
        surname: null,
        email: "test@test.test",
        facebook_img: null,
        facebook_id: null,
        jwt_token:
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vYXBpdjMtdGVzdGluZy1uLnByb21vcXVpLml0L2dyYXBocWwiLCJpYXQiOjE2Njc0NzMyNTAsImV4cCI6MTY2NzQ3MzMxMCwibmJmIjoxNjY3NDczMjUwLCJqdGkiOiJJTmZ0TENwcmFjWXFGTXk2Iiwic3ViIjoxMjk2MTAsInBydiI6Ijg3ZTBhZjFlZjlmZDE1ODEyZmRlYzk3MTUzYTE0ZTBiMDQ3NTQ2YWEifQ.94du322UYiRiSR_AEZpKAW4-QV43KZiqiihHVrN1hlk",
        jwt_token_expires: 1667473310,
        __typename: "User",
      }; */

      const { data } = await client.mutate<RegisterUserMutation>({
        mutation: RegisterUserDocument,
        variables,
      });

      const userData = new UserModel(data.profileUser as User);

      return userData;
    } catch (reason) {
      console.error(reason, "error signup"); // Error!
      return rejectWithValue(reason.message);
    }
  },
);

const rdxUserLogin = createAsyncThunk<
  UserModel,
  { username: string; password: string },
  {
    state: RootState;
  }
>(rdxUserEvents.LOGIN, async (params, { rejectWithValue, getState }) => {
  return null;

  try {
    const position = await LocalizeMe.localize();
    const geolocation = new Geolocation();
    const location = await geolocation.byLatLon(
      position.coords.latitude,
      position.coords.longitude,
    );
    const { rStateDeviceUser } = ccontextSelector(getState());
    const { data } = await client.mutate<UserLoginMutation>({
      mutation: UserLoginDocument,
      variables: {
        email: params.username,
        password: params.password,
        lat: location.latitude,
        lng: location.longitude,
        deviceId: DeviceIdUtility.getId(rStateDeviceUser),
        location:
          location.latitude && location.longitude
            ? `${location.latitude},${location.longitude}`
            : "",
      },
    });

    const userData = new UserModel(data.ProfileUser);

    UserUtility.login(userData);
    return userData;
  } catch (reason) {
    console.error(reason, "error login"); // Error!
    return rejectWithValue(reason.message);
  }
});

const rdxGoogleLogin = createAsyncThunk<User, string, { state: RootState }>(
  rdxUserEvents.GOOGLE_LOGIN,
  async (token, { rejectWithValue, getState }) => {
    try {
      const { rStateDeviceUser } = ccontextSelector(getState());
      const location = locationSelector(getState());

      const userLocation = location?.userLocation
        ? new LocationModel(location?.userLocation)
        : null;

      const { data } = await client.mutate<UserSocialLoginMutation>({
        mutation: UserSocialLoginDocument,
        variables: {
          socialProvider: SocialProviderEnum.Google,
          token,
          deviceId: DeviceIdUtility.getId(rStateDeviceUser),
          location:
            userLocation?.latitude && userLocation?.longitude
              ? `${userLocation.latitude},${userLocation.longitude}`
              : "",
        },
      });

      const userModel = new UserModel(data.ProfileSocial);

      GTag.sendEvent(GTag.CATEGORY.PROFILES, GTag.ACTION.SIGNIN, "google");

      UserUtility.login(userModel);

      return userModel;
    } catch (reason) {
      console.error(reason, "error google login"); // Error!
      UserUtility.logout();
      return rejectWithValue(reason.message);
    }
  },
);

const rdxFacebookLogin = createAsyncThunk<
  UserModel,
  string,
  { state: RootState }
>(
  rdxUserEvents.FACEBOOK_LOGIN,
  async (token, { rejectWithValue, getState }) => {
    try {
      const { rStateDeviceUser } = ccontextSelector(getState());
      const location = locationSelector(getState());

      const userLocation = location?.userLocation
        ? new LocationModel(location?.userLocation)
        : null;

      const { data } = await client.mutate<UserSocialLoginMutation>({
        mutation: UserSocialLoginDocument,
        variables: {
          socialProvider: SocialProviderEnum.Facebook,
          token,
          deviceId: DeviceIdUtility.getId(rStateDeviceUser),
          location:
            userLocation?.latitude && userLocation?.longitude
              ? `${userLocation.latitude},${userLocation.longitude}`
              : "",
        },
      });

      const userModel = new UserModel(data.ProfileSocial);

      GTag.sendEvent(GTag.CATEGORY.PROFILES, GTag.ACTION.SIGNIN, "facebook");

      UserUtility.login(userModel);

      return userModel;
    } catch (reason) {
      console.error(reason, "error facebook login"); // Error!
      UserUtility.logout();
      return rejectWithValue(reason.message);
    }
  },
);

export {
  rdxUserInit,
  rdxUserLogout,
  rdxUserLogin,
  rdxGoogleLogin,
  rdxFacebookLogin,
  setShowLoginDialog,
  rdxUserUpdate,
  rdxUserSignup,
  //rdxUserLoginFail,
};
