const LocationConstants = {
  GPS: "gps",
  USER: "user",
  IP: "ip",
  URL: "url",
  DEFAULT: "default",
  BOT: "bot",
};

export { LocationConstants };
