const EntityTypeConstants = {
  CATEGORY: "Category",
  RETAILER: "Retailer",
  BRAND: "Brand",
  PRODUCT: "Product",
  OFFER: "Offer",
  SHOPPING_CENTER: "ShoppingCenter",
  TEXT: "Text",
  CITY: "City",
};

const EntityCommentTypeConstants = {
  RETAILER: "RETAILER",
  SHOPPING_CENTER: "BASE_STORE",
  BRAND: "BRAND",
  CATEGORY: "CATEGORY",
  STORE: "BASE_STORE",
};

const EntityFavouritesType = {
  RETAILER: "Retailer",
  BRAND: "Brand",
  CATEGORY: "Category",
};

const EntityUserLikesLikeTypeConstants = {
  RETAILER: "Retailer",
  CATEGORY: "Category",
  BRAND: "Brand",
  STORE: "Store",
  HOME_PAGE_CATEGORY: "HomePageCategory",
  PRODUCT: "Product",
  OFFER: "Offer",
  OFFER_CONTAINER: "OfferContainer",
  SHOPPING_CENTER: "ShoppingCenter",
};

export {
  EntityCommentTypeConstants,
  EntityTypeConstants,
  EntityFavouritesType,
  EntityUserLikesLikeTypeConstants,
};
