import PqConsole from "@/commons/utilities/PqConsole";
import DeviceUserMutations from "@/commons/dataset/mutations/DeviceUserMutations";
import { useSelectorPQGlobalThis } from "@/commons/hooks/usePQGlobalThis";
import DeviceModel from "@/commons/dataset/models/DeviceModel";
class DeviceIdUtility {
  static async updateWhitFirebaseToken(
    firebaseToken,
    userLocation,
    rStateHeaders,
    rStateDeviceUser,
  ) {
    try {
      const { pqConfs } = useSelectorPQGlobalThis();
      let device = DeviceIdUtility.get(rStateDeviceUser);
      const deviceUserMutations = new DeviceUserMutations();

      const params = {
        deviceId: device.id,
        deviceType: `${rStateHeaders.browser.name} ver.${rStateHeaders.browser.version}`,
        platform: rStateHeaders?.ua,
        appVersion: pqConfs.appVersion,
        lat: userLocation?.latitude,
        lon: userLocation?.longitude,
        firebaseToken: firebaseToken,
      };
      device = await deviceUserMutations.update(params);
      return device;
    } catch (error) {
      PqConsole.error("updateWhitFirebaseToken not work", error);
    }
  }

  static get(rStateDeviceUser) {
    const device = new DeviceModel(rStateDeviceUser);
    return device;
  }

  static getId(rStateDeviceUser) {
    const device = new DeviceModel(rStateDeviceUser);

    if (device) {
      return device.id;
    }
    return null;
  }
}

export default DeviceIdUtility;
