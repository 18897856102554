import rdxUserEvents from "@/commons/redux/events/rdxUserEvents";
import {
  rdxFacebookLogin,
  rdxGoogleLogin,
  rdxUserInit,
  rdxUserLogin,
  rdxUserSignup,
} from "@/commons/redux/actions/rdxUserActions";

import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { RdxUserState } from "./typings";
import UserModel from "@/commons/dataset/models/UserModel";

const initialState: RdxUserState = {
  data: null,
  error: null,
  signupError: null,
  loading: false,
  showLoginDialog: false,
};

const rdxUserReducers = createReducer<RdxUserState>(initialState, (builder) => {
  builder.addCase(rdxUserInit, (state, action) => {
    state.data = action.payload;
    state.error = null;
    return state;
  });

  builder.addCase(
    rdxUserSignup.rejected,
    (state, action: PayloadAction<any>) => {
      state.data = null;
      state.error = null;
      state.signupError = action.payload;
      return state;
    },
  );

  builder.addCase(rdxUserLogin.pending, (state, _) => {
    state.loading = true;
    return state;
  });

  builder.addCase(rdxGoogleLogin.pending, (state, _) => {
    state.loading = true;
    return state;
  });

  builder.addCase(rdxFacebookLogin.pending, (state, _) => {
    state.loading = true;
    return state;
  });

  builder.addCase(
    rdxUserLogin.rejected,
    (state, action: PayloadAction<any>) => {
      if (state.data) state.data = null;
      state.loading = false;
      state.error = action.payload;
      return state;
    },
  );

  builder.addCase(
    rdxGoogleLogin.rejected,
    (state, action: PayloadAction<any>) => {
      if (state.data) state.data = null;
      state.loading = false;
      state.error = action.payload;
      return state;
    },
  );

  builder.addCase(
    rdxFacebookLogin.rejected,
    (state, action: PayloadAction<any>) => {
      if (state.data) state.data = null;
      state.loading = false;
      state.error = action.payload;
      return state;
    },
  );

  builder.addCase(rdxUserLogin.fulfilled, (state, action) => {
    state.data = action.payload;
    state.error = null;
    state.loading = false;
    state.showLoginDialog = false;
    return state;
  });

  builder.addCase(rdxGoogleLogin.fulfilled, (state, action) => {
    state.data = action.payload;
    state.error = null;
    state.loading = false;
    state.showLoginDialog = false;
    return state;
  });

  builder.addCase(rdxFacebookLogin.fulfilled, (state, action) => {
    state.data = action.payload;
    state.error = null;
    state.loading = false;
    state.showLoginDialog = false;
    return state;
  });

  builder.addCase(
    rdxUserEvents.SET_SHOW_LOGIN_DIALOG,
    (state, action: PayloadAction<boolean>) => {
      state.showLoginDialog = action.payload;
      return state;
    },
  );
  builder.addCase(
    rdxUserEvents.UPDATE,
    (state, action: PayloadAction<UserModel>) => {
      state.data = action.payload;
      state.error = null;
      return state;
    },
  );
  builder.addCase(rdxUserEvents.LOGOUT, (state, _) => {
    state = initialState;
    return state;
  });
  builder.addDefaultCase((state) => state);
});

export default rdxUserReducers;
export type { RdxUserState };
export { initialState };
