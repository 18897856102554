import routes from "./routes.js";
import footer from "./footer.js";
import ads from "./ads.js";
import login from "./login.js";

const config = {
  site: "IlikeSales",
  country: "uk",
  locale: "en",
  language: "en-EN",
  env: "production",
  maxDistance: 30000,
  settings: { disableImagesLazyLoad: false },
  frontendDomain: "https://www.ilikesales.co.uk",
  dataAsset: {
    url: "https://data.ilikesales.co.uk",
  },
  threshold: {
    store: {
      small: 6,
      medium: 25,
    },
  },
  gql: {
    device: "PROMOQUI_WEB",
    apiKey: "32d985869c12f71f1421eb47bda1b5543a337c13da8397775b677832e49698fa",
    domainGql: "https://apiv3.ilikesales.co.uk/graphql?",
    domainGqlAppauth: "https://apiv3.ilikesales.co.uk/graphql/appauth",
    domainGqlAuthenticated:
      "https://apiv3.ilikesales.co.uk/graphql/authenticated",
  },
  geolocation: {
    apiKey: "32d985869c12f71f1421eb47bda1b5543a337c13da8397775b677832e49698fa",
    domain: "https://api.ilikesales.co.uk/geolocation/",
  },
  socialLinks: {
    facebook: "https://www.facebook.com/ILikeSalesUK?fref=ts",
    twitter: "https://www.twitter.com/promoqui",
    googlePlus: "",
    pinterest: "https://it.pinterest.com/promoqui/",
  },
  apple: {
    clientId: "com.web.promoqui",
    appId: "466421194",
  },
  android: {
    appId: "it.promoqui.android",
  },
  iubenda: {
    siteId: 217068,
    cookiePolicyId: 731727,
    cookieId: "_iub_cs-731727",
    publicKey: "IyII26vhFcIMf5zq75GcXYjrfGMNOLOH",
    cookiePolicyURL:
      "https://www.iubenda.com/privacy-policy/731727/cookie-policy",
    localConsentDomain: "www.ilikesales.co.uk",
  },
  google: {
    googleMaps: "AIzaSyBBkh2ccr111gSqiSkpK-k9mqBmPr1V2Fc",
    googleClientID:
      "691043485490-0bkie4tpavs054h3t2uk7ed67sfu2uaf.apps.googleusercontent.com",
  },
  sentry: {
    enabled: false,
    dsn: "https://21bec954e41a4b91ab905ad09ae699e0@o150860.ingest.sentry.io/6726569",
  },
  appStore: {
    bannerApp: true,
    apple:
      "https://itunes.apple.com/gb/app/ilikesales-weekly-ads-local/id466421194",
    android:
      "https://play.google.com/store/apps/details?id=it.promoqui.android&hl=en",
    windows:
      "http://www.windowsphone.com/en-gb/store/app/ilikesales/e8a4ab90-792a-4536-8efe-c40eb5ec014f",
  },
  gAnalytics: {
    gtmId: "GTM-5FT362B",
    general: {
      id: "UA-25234055-10",
      label: "general",
    },
    trackingPage: {
      id: "",
      label: "leafletViewerTracker",
    },
  },
  shopfully: {
    api: {
      openStreetMap: "https://pq-maps.shopfully.cloud/styles/klokantech-basic",
    },
  },
  enableJSONLD: {
    retailer: true,
  },
};

export default {
  ...config,
  routes: routes,
  footer: footer,
  ads: ads,
  login: login,
};
