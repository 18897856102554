import locationEvents from "@/commons/redux/events/locationEvents";

const initialState = {
  urlLocation: null,
  userLocation: null,
  realLocation: null,
};
const locationReducers = (state = initialState, action) => {
  const { type, payload } = action;
  let realLocation;
  switch (type) {
    case locationEvents.SET_URL_LOCATION:
      realLocation = getRealLocation(state.userLocation, payload);
      state = { ...state, urlLocation: payload, realLocation };
      return state;
    case locationEvents.CLEAN_URL_LOCATION:
      realLocation = getRealLocation(state.userLocation, null);
      state = { ...state, urlLocation: null, realLocation };
      return state;
    case locationEvents.SET_USER_LOCATION:
      realLocation = getRealLocation(payload, state.urlLocation);
      state = { ...state, userLocation: payload, realLocation };
      return state;
    case locationEvents.CLEAN_USER_LOCATION:
      realLocation = getRealLocation(null, state.urlLocation);
      state = { ...state, userLocation: null, realLocation };
      return state;

    default:
      return state;
  }
};

const getRealLocation = (userLocation, urlLocation) => {
  if (!urlLocation) return userLocation;
  if (!userLocation) return urlLocation;
  if (userLocation.default) return urlLocation;
  return userLocation;
};

export default locationReducers;
export { initialState };
