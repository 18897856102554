import Model from "./Model";

class RegionModel extends Model {
  constructor(region = {}) {
    super(region);
    this.isoCode = region.iso_code;
    this.name = region.name;
  }

  static getCollection = (data) => data.map((d) => new RegionModel(d));
}

export default RegionModel;
