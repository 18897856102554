import Model from "./Model";
import ImageModel from "./ImageModel";
import SectionModel from "./SectionModel";

const setCover = (shoppingCenterInfo) => {
  return shoppingCenterInfo.cover
    ? new ImageModel(shoppingCenterInfo.cover)
    : null;
};

const setLogo = (shoppingCenterInfo) => {
  return shoppingCenterInfo.logo
    ? new ImageModel(shoppingCenterInfo.logo)
    : null;
};

const setSection = (shoppingCenterInfo) => {
  return shoppingCenterInfo.section
    ? new SectionModel(shoppingCenterInfo.section)
    : null;
};

class ShoppingCenterInfoModel extends Model {
  constructor(shoppingCenterInfo = {}) {
    super(shoppingCenterInfo);
    this._cover = setCover(shoppingCenterInfo);
    this._logo = setLogo(shoppingCenterInfo);

    this._description = shoppingCenterInfo.seo_description_markdown;
    this._parkingSpots = shoppingCenterInfo.parking_spots
      ? shoppingCenterInfo.parking_spots.trim()
      : null;
    this._floorsNumber =
      shoppingCenterInfo.floors_number > 0
        ? shoppingCenterInfo.floors_number
        : null;
    this._cinemaScreens = shoppingCenterInfo.cinema_screens > 0;
    this._disabledParkingBays = shoppingCenterInfo.disabled_parking_bays > 0;
    this._transferService =
      shoppingCenterInfo.transfer_service &&
      shoppingCenterInfo.transfer_service !== "";
    this._electricCarCharger =
      shoppingCenterInfo.electric_car_charger &&
      shoppingCenterInfo.electric_car_charger !== "";
    this._atm = shoppingCenterInfo.atm && shoppingCenterInfo.atm !== "";
    this._playField =
      shoppingCenterInfo.play_field && shoppingCenterInfo.play_field !== "";
    this._howToGetThere = shoppingCenterInfo.how_to_get_there;
    this._howToGetThereByTrain = shoppingCenterInfo.how_to_get_there_by_train;
    this._howToGetThereByCar = shoppingCenterInfo.how_to_get_there_by_car;
    this._howToGetThereByBus = shoppingCenterInfo.how_to_get_there_by_bus;
    this._numStores = shoppingCenterInfo.num_stores;
    this._externalUrl = shoppingCenterInfo.external_url;
    this._section = setSection(shoppingCenterInfo);
  }

  get cover() {
    return this._cover;
  }
  set cover(value) {
    this._cover = value;
  }

  get logo() {
    return this._logo;
  }
  set logo(value) {
    this._logo = value;
  }

  get description() {
    return this._description;
  }
  set description(value) {
    this._description = value;
  }

  get parkingSpots() {
    return this._parkingSpots;
  }
  set parkingSpots(value) {
    this._parkingSpots = value;
  }

  get floorsNumber() {
    return this._floorsNumber;
  }
  set floorsNumber(value) {
    this._floorsNumber = value;
  }

  get cinemaScreens() {
    return this._cinemaScreens;
  }
  set cinemaScreens(value) {
    this._cinemaScreens = value;
  }

  get disabledParkingBays() {
    return this._disabledParkingBays;
  }
  set disabledParkingBays(value) {
    this._disabledParkingBays = value;
  }

  get howToGetThere() {
    return this._howToGetThere;
  }
  set howToGetThere(value) {
    this._howToGetThere = value;
  }

  get howToGetThereByTrain() {
    return this._howToGetThereByTrain;
  }
  set howToGetThereByTrain(value) {
    this._howToGetThereByTrain = value;
  }

  get howToGetThereByCar() {
    return this._howToGetThereByCar;
  }
  set howToGetThereByCar(value) {
    this._howToGetThereByCar = value;
  }

  get howToGetThereByBus() {
    return this._howToGetThereByBus;
  }
  set howToGetThereByBus(value) {
    this._howToGetThereByBus = value;
  }

  get transferService() {
    return this._transferService;
  }
  set transferService(value) {
    this._transferService = value;
  }

  get playField() {
    return this._playField;
  }
  set playField(value) {
    this._playField = value;
  }

  get electricCarCharger() {
    return this._electricCarCharger;
  }
  set electricCarCharger(value) {
    this._electricCarCharger = value;
  }

  get atm() {
    return this._atm;
  }
  set atm(value) {
    this._atm = value;
  }

  get numStores() {
    return this._numStores;
  }
  set numStores(value) {
    this._numStores = value;
  }

  get externalUrl() {
    return this._externalUrl;
  }
  set externalUrl(value) {
    this._externalUrl = value;
  }

  get section() {
    return this._section;
  }
  set section(value) {
    this._section = value;
  }
}

export default ShoppingCenterInfoModel;
