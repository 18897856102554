import Model from "./Model";
import RegionModel from "./RegionModel";
import CountryModel from "./CountryModel";

const setRegion = (city) => {
  if (city.region) {
    return new RegionModel(city.region);
  }
  return null;
};

const setCountry = (city) => {
  if (city.county) {
    return new CountryModel(city.county);
  }
  return null;
};
class CityModel extends Model {
  constructor(city = {}) {
    super(city);

    this.name = city.name;
    this.url = city.url;
    this.slug = city.slug;
    this.zipCode = city.zipcode || city.zipCode;
    this.latitude = city.latitude;
    this.longitude = city.longitude;
    this.region = setRegion(city);
    this.county = setCountry(city);
    this.province = city.province;
    this.isProvince = city.is_province || city.isProvince;
    this.storeCount = city.store_count || city.storeCount;
  }

  static getCollection = (data) => data.map((d) => new CityModel(d));
}

export default CityModel;
