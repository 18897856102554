const locationKeyEvents = "STORE_LOCATION_";
const locationEvents = {
  SET_URL_LOCATION: `${locationKeyEvents}SET_URL_LOCATION`,
  CLEAN_URL_LOCATION: `${locationKeyEvents}CLEAN_URL_LOCATION`,
  SET_USER_LOCATION: `${locationKeyEvents}SET_USER_LOCATION`,
  UPDATE_USER_LOCATION: `${locationKeyEvents}UPDATE_USER_LOCATION`,
  CLEAN_USER_LOCATION: `${locationKeyEvents}CLEAN_USER_LOCATION`,
};

export default locationEvents;
export { locationKeyEvents };
