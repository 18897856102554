const ccontextKeyEvents = "STORE_CCONTEXT_";
const ccontextEvents = {
  SET_HEADERS: `${ccontextKeyEvents}SET_HEADERS`,
  SET_DEVICE_USER: `${ccontextKeyEvents}SET_DEVICE_USER`,
  SET_APP_RENDER: `${ccontextKeyEvents}SET_APP_RENDER`,
  SET_ACCEPT_WEBP: `${ccontextKeyEvents}SET_ACCEPT_WEBP`,
  SET_IS_CHROME_LIGHTHOUSE: `${ccontextKeyEvents}SET_IS_CHROME_LIGHTHOUSE`,
  SET_IS_BOT: `${ccontextKeyEvents}SET_IS_BOT`,
  SET_USER_IP: `${ccontextKeyEvents}SET_USER_IP`,
  SET_LOCATION_HREF: `${ccontextKeyEvents}SET_LOCATION_HREF`,
  SET_GOOGLE_ANALYTICS: `${ccontextKeyEvents}SET_GOOGLE_ANALYTICS`,
  SET_RENDERTRON: `${ccontextKeyEvents}SET_RENDERTRON`,
};

export default ccontextEvents;
export { ccontextKeyEvents };
