const ContextKeys = {
  PQ_LANGUAGE_TRANSLATE: "pqLanguageTranslate",
  PQ_LANGUAGE_VERSION: "pqLanguageVersion",
  PQ_CONFS: "pqConfs",
  PQ_IMAGES: "pqImages",
  PQ_HOMEPAGE_CATEGORIES: "pqHomePageCategories",
  PQ_URL_LOCATION: "_pqUrlLocation", // test purpose (TODO REMOVE or migrate to Redix)
  PQ_USER_LOCATION: "_pqUserLocation", // test purpose (TODO REMOVE or migrate to Redix)
  /* 	PQ_XXX_LOCATION: '_pqXXXLocation', // test purpose (TODO REMOVE or migrate to Redix)
   */
};

export default ContextKeys;
