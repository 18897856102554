import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import TokenManager from "@/commons/dataset/manager/TokenManager";
import { disableFragmentWarnings } from "graphql-tag";
import includes from "lodash-es/includes";
import { useSelectorPQGlobalThis } from "@/commons/hooks/usePQGlobalThis";
import PqConsole from "@/commons/utilities/PqConsole";

class Mutations {
  constructor(urlClient) {
    const { pqConfs } = useSelectorPQGlobalThis();
    this.urlClient = urlClient || pqConfs.gql.domainGql;
    disableFragmentWarnings();
  }

  printGql(name, gqlQuery, variables) {
    return;
    PqConsole.loqQuery(`\n----------------
    \nquery name: ${name.replace(/(\r\n|\n|\r)/gm, " ").replace(/(  )/gm, " ")}
    \nurlClient: ${this.urlClient}
    \nquery: ${gqlQuery.replace(/(\r\n|\n|\r)/gm, " ").replace(/(  )/gm, " ")}
    \nvariables: ${JSON.stringify(variables)}
    \n----------------`);
  }

  getClient(token) {
    const client = new ApolloClient({
      uri: this.urlClient,
      headers: { authorization: `Bearer ${token.value}` },
      cache: new InMemoryCache(),
    });
    return client;
  }

  getFragments(fragments) {
    if (fragments) {
      return fragments
        .map((fragment) => fragment.gql.loc.source.body)
        .join(" ");
    }
    return "";
  }

  getMutation(name, mutation, data, fragments = null, variables = {}) {
    fragments = this.getFragments(fragments);
    data = data != "" ? `{ ${data} }` : "";
    const gqlMutation = `mutation ${name}{ ${mutation}${data} } ${fragments}`;
    this.printGql(name, gqlMutation, variables);
    const paramsMutation = {
      mutation: gql`
        ${gqlMutation}
      `,
      variables: variables,
    };

    return paramsMutation;
  }

  async getTokenForMutation() {
    const token = await TokenManager.getToken();
    return token;
  }

  async getTokenRetryMutation() {
    const token = await TokenManager.refreshToken();
    return token;
  }

  execMutation(
    name,
    mutation,
    data = null,
    fragments = null,
    variables = {},
    returnJson = false,
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        const paramsMutation = this.getMutation(
          name,
          mutation,
          data,
          fragments,
          variables,
        );

        const token = await this.getTokenForMutation();
        const client = this.getClient(token);
        const resultMutation = await client.mutate(paramsMutation);
        if (resultMutation) {
          const parseResultMutation = this.getResult(
            resultMutation,
            returnJson,
          );
          resolve(parseResultMutation);
        } else {
          reject(`PQ ERROR: ${name} result not found`);
        }
      } catch (error) {
        if (this.isRetryError(error)) {
          try {
            const token = await this.getTokenRetryMutation();
            const client = this.getClient(token);
            const resultMutation = await client.mutate(paramsMutation);
            if (resultMutation) {
              const parseResultMutation = this.getResult(
                resultMutation,
                returnJson,
              );
              resolve(parseResultMutation);
            } else {
              reject(`PQ ERROR: ${name} result not found`);
            }
          } catch (error) {
            reject(error);
          }
        } else {
          reject(error);
        }
      }
    });
  }

  isRetryError(error) {
    return includes([401, 403], error?.networkError?.statusCode);
  }

  getResult(result, returnJson = false) {
    throw new Error(
      "PQ ERROR: implementare il metodo getResult nella classe di Mutations",
    );
  }
}

export default Mutations;
