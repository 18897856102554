import DateTimeUtility from "@/commons/utilities/DateTimeUtility";
import TokenUserMutations from "@/commons/dataset/mutations/TokenUserMutations";
import PqConsole from "@/commons/utilities/PqConsole";
import {
  rdxUserLogout,
  rdxUserUpdate,
} from "@/commons/redux/actions/rdxUserActions";
import { storeRedux } from "@/commons/redux/reducers/stores";

class TokenUserManager {
  static async getTokenUser(userData, dispatch) {
    if (userData) {
      const expires =
        userData.tokenExpires - TokenUserManager.CONFIG.OFFSET_EXPIRED_TOKEN;
      if (DateTimeUtility.isExpired(expires)) {
        //token esistente ma scaduto...in questo caso eseguo il Renewal
        return await TokenUserManager.refreshTokenUser(userData, dispatch);
      } else {
        //token esistente e valido...in questo caso lo resituisco
        return userData.token;
      }
    } else {
      /* Da testare */
      storeRedux?.dispatch(rdxUserLogout());
    }

    return null;
  }

  static async refreshTokenUser(userData, dispatch) {
    if (userData) {
      if (
        !TokenUserManager.promiseRenewalToken ||
        TokenUserManager.oldToken != userData.token
      ) {
        TokenUserManager.oldToken = userData.token;
        TokenUserManager.promiseRenewalToken = new Promise(
          async (resolve, reject) => {
            try {
              const tokenUserMutations = new TokenUserMutations();
              const result = await tokenUserMutations.renewal({
                token: userData.token,
              });
              if (result) {
                storeRedux?.dispatch(rdxUserUpdate(result));
                resolve(result.token);
              } else {
                storeRedux?.dispatch(rdxUserLogout());
                const error = new Error("not token refreshed");
                PqConsole.error(error);
                reject(error);
                window.location.reload();
              }
            } catch (error) {
              storeRedux?.dispatch(rdxUserLogout());
              PqConsole.error(error);
              reject(error);
              //window.location.reload();
            }
          },
        );
      }

      return TokenUserManager.promiseRenewalToken;
    }

    return new Promise((resolve, reject) => {
      resolve(null);
    });
  }
}
TokenUserManager.promiseRenewalToken = null;
TokenUserManager.oldToken = null;

TokenUserManager.CONFIG = {
  OFFSET_EXPIRED_TOKEN: 600,
};

export default TokenUserManager;
