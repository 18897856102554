import BrandModel from "./BrandModel";
import ImageModel from "./ImageModel";
import Model from "./Model";
import RetailerModel from "./RetailerModel";

// DA ELIMINARE ...

const setIcon = (category, field, ext = "") => {
  if (category[`${field}${ext}`]) {
    return new ImageModel(category[`${field}${ext}`]);
  }
  return null;
};

const setAssociatedRetailers = (category) => {
  if (category.associated_retailers)
    return RetailerModel.getCollection(category.associated_retailers);
  return null;
};

const setChildren = (category) => {
  if (category.children) return CategoryModel.getCollection(category.children);
  return null;
};

const setBrand = (category) => {
  if (category.brand) return new BrandModel(category.brand);
  return null;
};

const setRelated = (category) => {
  if (category.related) return CategoryModel.getCollection(category.related);
  return null;
};

const setMainCategory = (category) => {
  if (category.main_category) return new CategoryModel(category.main_category);
  return null;
};

const setParentCategory = (category) => {
  if (category.parent) return new CategoryModel(category.parent);
  return null;
};
class CategoryModel extends Model {
  constructor(category = {}) {
    super(category);

    this.name = category.name;
    this.slug = category.slug;

    //--- icon / flatIcon ---
    this.icon = setIcon(category, "icon", "");
    this.iconWebP = setIcon(category, "icon", "_webp");
    this.iconWebPSupportedFormats =
      category.icon_webp_supported_formats || category.iconWebPSupportedFormats;
    this.flatIcon = setIcon(category, "flat_icon", "") || category.flatIcon;
    this.flatIconWebP =
      setIcon(category, "flat_icon", "_webp") ||
      category.flatIconWebp ||
      category.flatIconWebP;
    this.flatIconWebPSupportedFormats =
      category.flat_icon_webp_supported_formats ||
      category.flatIconWebPSupportedFormats ||
      category.flatIconWebpSupportedFormats;
    //------------

    this.seoDescription = category.seo_description || category.seoDescription;
    this.metaTitle = category.meta_title;
    this.metaNationalTitle = category.meta_national_title;
    this.metaDescription = category.meta_description;
    this.localDescription =
      category.local_description || category.localDescription;
    this.nationalDescription =
      category.national_description || category.nationalDescription;
    this.children = setChildren(category);
    this.offersCount = category.offers_count || category.offersCount;
    this.related = setRelated(category);
    this.mainCategory = setMainCategory(category);
    this.parentCategory = setParentCategory(category);
    this.associatedRetailers = setAssociatedRetailers(category);
    this.brand = setBrand(category);
    this.typename = category.__typename;
  }

  get existsIcon() {
    return this.icon !== null || this.iconWebP != null;
  }

  get existsflatIcon() {
    return this.flatIcon !== null || this.flatIconWebP != null;
  }

  static getCollection = (data) => data.map((d) => new CategoryModel(d));
}

export default CategoryModel;
