const LocalStorageKeys = {
  KEY_TRANSLATE: "PQ_LANGUAGE_TRANSLATE",
  KEY_VERSION: "PQ_LANGUAGE_VERSION",
  KEY_VISITED_LEAFLETS: "leaflets",
  KEY_LAYOUT_LEAFLET_VIEWER: "leafletViewerLayout",
  KEY_INTERSECTION_LEAFLET: "INTERSECTION_LEAFLET",
  KEY_PQ_PUSH_DATA: "_pqpush_data",
};

export default LocalStorageKeys;
