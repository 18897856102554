import { onError } from "@apollo/client/link/error";
import { Observable } from "@apollo/client/utilities/observables/Observable";
import TokenManager from "@/commons/dataset/manager/TokenManager";

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[Apollo GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );
    }

    if (networkError) {
      console.log(`[Apollo Network error]: ${networkError}`);

      if (networkError.message.indexOf("code 401")) {
        console.log("[Apollo Network]: Expired Token Renewal");
        return new Observable((observer) => {
          TokenManager.refreshToken()
            .then((token) => {
              operation.setContext(({ headers = {} }) => ({
                headers: {
                  ...headers,
                  authorization: token ? `Bearer ${token.value}` : "",
                },
              }));
            })
            .then(() => {
              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              };

              // Retry last failed request
              forward(operation).subscribe(subscriber);
            })
            .catch((error) => {
              // No refresh or client token available
              observer.error(error);
            });
        });
      }
    }
  },
);

export { errorLink };
