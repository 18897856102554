import { EntityCommentTypeConstants } from "@/commons/dataset/constants/EntityConstants";
import UserUtility from "@/commons/utilities/UserUtility";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "apollo";
import {
  CommentsSorting,
  GetCommentsQuery,
  GetCommentsDocument,
  CreateCommentMutation,
  CreateCommentDocument,
  DeleteCommentMutation,
  DeleteCommentDocument,
} from "generated/graphql/types";
import commentsInEvents from "../events/commentsEvents";
import { CommentsReducerState } from "../reducers/commentsReducers/typings";
import { setShowLoginDialog } from "./rdxUserActions";

const ERRORS = {
  NO_ERROR: 0,
  REQUIRE_MSG: 1,
  ERROR_ADD: 2,
};

const getComments = createAsyncThunk<
  any,
  any,
  {
    state: CommentsReducerState;
  }
>(commentsInEvents.GET_COMMENTS, async (params, { rejectWithValue }) => {
  try {
    const rdxUser = UserUtility.getUserData();

    let commentableId: number;
    let commentableType: string;

    if (params.retailer) {
      commentableId = params.retailer.id;
      commentableType = EntityCommentTypeConstants.RETAILER;
    } else if (params.category) {
      commentableId = params.category.id;
      commentableType = EntityCommentTypeConstants.CATEGORY;
    }

    const defaultParams = {
      commentableType: commentableType,
      commentableId: commentableId,
      displayedByUserId: rdxUser?.id,
      returnJson: true,
      sorting: CommentsSorting.DateDesc,
    };

    const { data } = await client.query<GetCommentsQuery>({
      query: GetCommentsDocument,
      variables: {
        ...defaultParams,
      },
    });
    return data.Comments;
  } catch (reason) {
    console.error(reason, "error login"); // Error!
    return rejectWithValue(reason.message);
  }
});

const addComment = createAsyncThunk<any, any>(
  commentsInEvents.ADD_COMMENT,
  async (variables, { rejectWithValue, getState, dispatch }) => {
    try {
      const rdxUser = UserUtility.getUserData();

      if (!rdxUser) {
        dispatch(setShowLoginDialog(true));
        throw Error("Not logged ...");
      }

      const { data } = await client.mutate<CreateCommentMutation>({
        mutation: CreateCommentDocument,
        variables,
      });

      return data.CreateComment.returning;
    } catch (reason) {
      console.error(reason, "error add comment"); // Error!

      return rejectWithValue(ERRORS.ERROR_ADD);
    }
  },
);

const deleteComment = createAsyncThunk<any, { replyId: number; id: number }>(
  commentsInEvents.DELETE_COMMENT,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await client.mutate<DeleteCommentMutation>({
        mutation: DeleteCommentDocument,
        variables: {
          id: params.replyId ?? params.id,
        },
      });

      return data.DeleteComment.returning;
    } catch (reason) {
      console.error(reason, "error remove comment"); // Error!
      return rejectWithValue(ERRORS.ERROR_ADD);
    }
  },
);

export { getComments, deleteComment, addComment };
