export default {
  homePage: "/",
  passwordRecovery: "/recupera-password",
  homePageLocal: "/%{city}",
  leafletPreview: "/nuevos-catalogos",
  leafletPreviewLocal: "/%{city}/nuevos-catalogos",
  leaflet: "/catalogo/%{retailer}/%{id}",
  leafletWithPage: "/catalogo/%{retailer}/%{id}#p=%{page}",
  leafletWithOffer: "/catalogo/%{retailer}/%{id}#p=%{page}&o=%{selectionArea}",
  leafletWithOfferId:
    "/catalogo/%{retailer}/%{id}%{divisorUrl}p=%{page}&oid=%{offerId}",
  leafletWithOnlyOfferId:
    "/catalogo/%{retailer}/%{id}%{divisorUrl}oid=%{offerId}",
  leafletWithCategoryLabel:
    "/catalogo/%{retailer}/%{id}%{divisorUrl}category=%{category}",
  retailer: "/%{section}/%{slug}",
  retailerLocal: "/%{city}/%{section}/%{slug}",
  retailerAmp: "/%{section}/%{slug}/amp",
  retailerLocalAmp: "/%{city}/%{section}/%{slug}/amp",
  offer: "/ofertas/%{slug}",
  offerLocal: "/%{city}/ofertas/%{slug}",
  offerCategory: "/ofertas/%{category}",
  offerCategoryLocal: "/%{city}/ofertas/%{category}",
  offerRetailerCategory: "/ofertas/%{retailer}/%{category}",
  offerPreferredLeaflets: "/ofertas/volantini-preferiti",
  product: "/productos/%{category}/%{product}",
  search: "/search?q=%{key}",
  searchModel: "/search?q=%{key}&model=1",
  shoppingCenter: "/%{city}/%{section}/%{slug}",
  store: "/%{city}/%{section}/%{slug}",
  storeRetailer: "/tiendas/%{slug}",
  storeRetailerLocal: "/%{city}/tiendas/%{slug}",
  nationalStore: "/tiendas/%{slug}",
  shoppingList: "/preferencias/lista-spesa",
  shoppingListShare: "/preferencias/share_shopping_list/%{shareHash}",
  preferencesRegistration: "/preferencias/sign-up",
  preferencesStores: "/preferencias/tiendas",
  preferencesProducts: "/preferencias/productos",
  preferencesCategories: "/preferencias/categorias",
  preferencesManage: "/preferencias/manage",
  localita: "/ubication/%{firstLetter}",
  loyaltyCard: "/tarjetas-de-fidelidad/%{slug}",
  indexCategories: "/oferta-catalogos/%{firstLetter}",
  indexCategoriesLocal: "/%{city}/oferta-catalogos.%{firstLetter}",
  indexStores: "/tiendas/%{firstLetter}",
  indexShoppingCenters: "/centro-comercial/%{firstLetter}",
  requestPersonalData: "/preferencias/solicitar-datos-personales",
  mobileApp: "/mobile-app",
  retailers: "/tiendas",
  who: "/quienes-somos",
  how: "/como-funciona",
  eco: "/eco",
  blog: "/blog",
  allBrands: "/marcas",
  allCategories: "/oferta-catalogos",
  allCategoriesLocal: "/%{city}/oferta-catalogos",
  allLoyaltyCards: "/tarjetas-de-fidelidad",
  allCities: "/ubication",
  allCitiesRanged: "/ubication/%{range}",
  allOpenStores: "/tiendas/aperti-oggi",
  allOpenStoresLocal: "/%{city}/tiendas/aperti-oggi",
  allRetailers: "/tiendas",
  allRetailersRanged: "/tiendas/%{range}",
  allRetailersLocal: "/%{city}/tiendas",
  allShoppingCenters: "/centro-comercial",
};
