import routes from "./routes.json";

const config = {
  site: "PromoQui",
  country: "it",
  locale: "it",
  language: "en-AU",
  env: "production",
  frontendDomain: "www.promoqui.it",
  maxDistance: 30000,
  dataAsset: {
    url: "https://data.promoqui.it",
  },
  gql: {
    device: "PROMOQUI_WEB",
    apiKey: "32d985869c12f71f1421eb47bda1b5543a337c13da8397775b677832e49698fa",
    domainGql: "https://apiv3-testing-n.promoqui.it/graphql?",
    domainGqlAppauth: "https://apiv3-testing-n.promoqui.it/graphql/appauth",
    domainGqlAuthenticated:
      "https://apiv3-testing-n.promoqui.it/graphql/authenticated",
  },
  socialLinks: {
    facebook: "https://www.facebook.com/PromoQui",
    twitter: "https://www.twitter.com/promoqui",
    googlePlus: "https://plus.google.com/+PromoquiItSpa",
    pinterest: "https://it.pinterest.com/promoqui/",
  },
  footer: {
    links: {
      corporate: {
        active: true,
        link: "https://corporate.shopfully.com/it/",
      },
      whoWeAre: {
        active: true,
        link: "https://corporate.shopfully.com/it/chi-siamo/",
      },
      workingWithUs: {
        active: true,
        link: "https://corporate.shopfully.com/it/lavora-con-noi/",
      },
      corporateContactUs: {
        active: true,
        link: "https://corporate.shopfully.com/it/contatti/",
      },
      who: {
        active: true,
      },
      how: {
        active: true,
      },
      app: {
        active: true,
      },
      eco: {
        active: true,
      },
      smart: {
        active: true,
      },
      agencies: {
        active: false,
      },
      contactUs: {
        active: true,
      },
      blog: {
        active: false,
      },
      allStores: {
        active: true,
      },
      allShoppingCenters: {
        active: true,
      },
      allCheaperOffers: {
        active: true,
      },
    },
  },
  apple: {
    clientId: "com.web.promoqui",
  },
  iubenda: {
    publicKey: "IyII26vhFcIMf5zq75GcXYjrfGMNOLOH",
    cookiePolicyURL:
      "https://www.iubenda.com/privacy-policy/962771/cookie-policy",
    localConsentDomain: "www.promoqui.it",
  },
  google: {
    googleMaps: "AIzaSyBtalFrzwZsFhlU3PIf00vAIOv31GpmiaQ",
    googleClientID:
      "691043485490-0bkie4tpavs054h3t2uk7ed67sfu2uaf.apps.googleusercontent.com",
  },
  sentry: {
    enabled: false,
    dsn: "https://bc2018745dc14594a81684491337d202@sentry.io/1201101",
  },
  appStore: {
    bannerApp: true,
    apple: "https://itunes.apple.com/it/app/promoqui/id466421194?mt=8",
    android:
      "https://play.google.com/store/apps/details?id=it.promoqui.android",
    windows: "https://www.microsoft.com/it-it/store/p/promoqui/9nblggh091xj",
  },
  gAnalytics: {
    gtmId: "GTM-MB4CM68",
    general: {
      id: "UA-25234055-1",
      label: "general",
    },
    trackingPage: {
      id: "UA-25234055-20",
      label: "leafletViewerTracker",
    },
  },
  shopfully: {
    api: {
      openStreetMap: "https://pq-maps.shopfully.cloud/styles/klokantech-basic",
    },
  },
};

export default { ...config, routes: routes };
