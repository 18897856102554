import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Comment } from "generated/graphql/types";
import { CommentsReducerState } from "./typings";
import {
  addComment,
  deleteComment,
  getComments,
} from "../../actions/commentsActions";

const initialState: CommentsReducerState = {
  commentsList: [],
  commentDraft: null,
};

const commentsSlice = createSlice({
  name: "comments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getComments.fulfilled,
      (state, action: PayloadAction<Comment[]>) => {
        state.commentsList = action.payload;
        return state;
      },
    );
    builder.addCase(addComment.pending, (state, { meta }) => {
      if (meta.arg) state.commentDraft = meta.arg;
      return state;
    });
    builder.addCase(addComment.fulfilled, (state, action) => {
      if (action.meta.arg?.replyToCommentId) {
        const index = state.commentsList.findIndex(
          (comment) => comment.id === action.meta.arg?.replyToCommentId,
        );
        state.commentsList[index].replies = [
          action.payload,
          ...state.commentsList[index].replies,
        ];
      } else {
        state.commentsList = [action.payload, ...state.commentsList];
      }

      state.commentDraft = null;
      return state;
    });

    builder.addCase(deleteComment.fulfilled, (state, action) => {
      if (action.meta.arg?.replyId) {
        const index = state.commentsList.findIndex(
          (comment) => comment?.id === action.meta.arg?.id,
        );
        if (index >= 0) {
          state.commentsList[index].replies = state.commentsList[
            index
          ].replies.filter((reply) => reply.id !== action.meta.arg.replyId);
        }
      } else {
        state.commentsList = state.commentsList.filter(
          (comment) => comment.id !== action.payload.id,
        );
      }

      state.commentDraft = null;
      return state;
    });
  },
});

export { initialState, getComments, addComment, deleteComment };

export default commentsSlice.reducer;
