import routes from "./routes.js";
import footer from "./footer.js";

const config = {
  site: "IlikeSales",
  country: "es",
  locale: "es",
  language: "es-ES",
  env: "production",
  maxDistance: 30000,
  settings: { disableImagesLazyLoad: false },
  frontendDomain: "https://www.ilikesales.es",
  dataAsset: {
    url: "https://data.ilikesales.es",
  },
  threshold: {
    store: {
      small: 6,
      medium: 25,
    },
  },
  gql: {
    device: "PROMOQUI_WEB",
    apiKey: "32d985869c12f71f1421eb47bda1b5543a337c13da8397775b677832e49698fa",
    domainGql: "https://apiv3.ilikesales.es/graphql?",
    domainGqlAppauth: "https://apiv3.ilikesales.es/graphql/appauth",
    domainGqlAuthenticated: "https://apiv3.ilikesales.es/graphql/authenticated",
  },
  geolocation: {
    apiKey: "32d985869c12f71f1421eb47bda1b5543a337c13da8397775b677832e49698fa",
    domain: "https://api.ilikesales.es/geolocation/",
  },
  socialLinks: {
    facebook:
      "https://www.facebook.com/pages/ILikeSales-Argentina/471571956352719?fref=ts",
    twitter: "https://www.twitter.com/promoqui",
    googlePlus: "",
    pinterest: "https://it.pinterest.com/promoqui/",
  },
  footer: footer,
  apple: {
    clientId: "",
  },
  iubenda: {
    siteId: 217068,
    cookiePolicyId: 962771,
    cookieId: "_iub_cs-962771",
    publicKey: "IyII26vhFcIMf5zq75GcXYjrfGMNOLOH",
    cookiePolicyURL:
      "https://www.iubenda.com/privacy-policy/962771/cookie-policy",
    localConsentDomain: "www.ilikesales.es",
  },
  google: {
    googleMaps: "AIzaSyBtalFrzwZsFhlU3PIf00vAIOv31GpmiaQ",
    googleClientID:
      "691043485490-0bkie4tpavs054h3t2uk7ed67sfu2uaf.apps.googleusercontent.com",
  },
  sentry: {
    enabled: false,
    dsn: "https://7b2618fdd0fd44cbb244484abc6e73be@sentry.io/1208307",
  },
  appStore: {
    bannerApp: true,
    apple:
      "https://itunes.apple.com/ar/app/ilikesales-weekly-ads-local/id466421194",
    android:
      "https://play.google.com/store/apps/details?id=it.promoqui.android&amp;hl=es",
    windows: "https://www.microsoft.com/it-it/store/p/promoqui/9nblggh091xj",
  },
  gAnalytics: {
    gtmId: "",
    general: {
      id: "UA-25234055-23",
      label: "",
    },
    trackingPage: {
      id: "",
      label: "leafletViewerTracker",
    },
  },
  shopfully: {
    api: {
      openStreetMap: "https://pq-maps.shopfully.cloud/styles/klokantech-basic",
    },
  },
};

export default { ...config, routes: routes };
