import { gql } from "@apollo/client";

class CitiesFragments {
  static default() {
    const name = "city_default";
    return {
      name: name,
      gql: gql`fragment ${name} on City {
        id name slug zipcode
      }`,
    };
  }

  static storeCount() {
    const name = "city_default";
    return {
      name: name,
      gql: gql`fragment ${name} on City {
        id name slug zipcode store_count
      }`,
    };
  }
}

export default CitiesFragments;
