import usePQTranslations from "@/commons/hooks/usePQTranslations";
import { useSelectorPQGlobalThis } from "@/commons/hooks/usePQGlobalThis";
import PqConsole from "@/commons/utilities/PqConsole";

class DateTimeUtility {
  static newFromString(input, format = "yyyy-mm-dd") {
    try {
      const parts = input.match(/(\d+)/g);
      let i = 0;
      let fmt = {};
      format.replace(/(yyyy|dd|mm)/g, function (part) {
        fmt[part] = i++;
      });
      const date = new Date(
        parts[fmt["yyyy"]],
        parts[fmt["mm"]] - 1,
        parts[fmt["dd"]],
      );
      date.setHours(23, 59, 59);
      return date;
    } catch (error) {
      return null;
    }
  }

  static isAboutToExpire(dateString = "") {
    const today = new Date();
    const date = DateTimeUtility.newFromString(dateString);
    if (!date) return null;
    const diffTime = date.getTime() - today.getTime();
    return Math.floor(diffTime / (1000 * 60 * 60 * 24)) <= 3;
  }

  static validsUntil(dateString = "") {
    if (dateString == "") return "";
    const today = new Date();
    const date = DateTimeUtility.newFromString(dateString);
    if (!date) return null;

    //const diffTime = Math.abs(date.getTime() - today.getTime());
    const diffTime = date.getTime() - today.getTime();
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const t = usePQTranslations();
    if (diffDays < 0) {
      return t("leaflets.full_valids_until_expired");
    } else if (diffDays == 0) {
      return t("leaflets.full_valids_until_today");
    } else if (diffDays == 1) {
      return t("leaflets.full_valids_until_tomorrow");
    } else if (diffDays <= 6) {
      return t("leaflets.expires", { day: t("days")[date.getDay()] });
    } else {
      return t("leaflets.full_valids_until", {
        date: `${date.getDate()}/${date.getMonth() + 1}`,
      });
    }
  }

  static validsFrom(dateString = "") {
    if (dateString == "") return "";

    const today = new Date();
    const date = DateTimeUtility.newFromString(dateString);
    if (!date) return null;

    const diffTime = Math.abs(today.getTime() - date.getTime());
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const diffWeek = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 7));
    const t = usePQTranslations();
    if (diffDays == 1) {
      return t("leaflets.valids_from_tomorrow");
    } else if (diffDays >= 6) {
      return t("leaflets.valids_from_date", {
        date: `${date.getDate()}/${date.getMonth()}`,
      });
    } else {
      return t("leaflets.valids_from_week_day", { week: diffWeek });
    }
  }

  static decorateDate(timestamp) {
    let date = new Date(parseInt(timestamp));
    let now = new Date();

    let diff = Math.abs(date.getTime() - now.getTime());

    let diffInMinute = Math.round(diff / (1000 * 60));
    let diffInHour = Math.round(diffInMinute / 60);
    let diffInDay = Math.round(diffInHour / 24);
    let diffInMonth = Math.round(diffInDay / 30);
    let diffInYear = Math.round(diffInDay / 365);
    const t = usePQTranslations();
    if (diffInMinute < 1) {
      return t("datetime.now");
    } else if (diffInMinute >= 1 && diffInMinute < 60) {
      return diffInMinute + " " + t("datetime.min");
    } else if (diffInHour >= 1 && diffInHour < 24) {
      return diffInHour + " " + t("datetime.h");
    } else if (diffInDay >= 1 && diffInDay <= 30) {
      return diffInDay + " " + t("datetime.d");
    } else if (diffInMonth >= 1 && diffInMonth <= 12) {
      return diffInMonth + " " + t("datetime.m");
    } else {
      return diffInYear + " " + t("datetime.y");
    }
  }

  static diffDays(dateString = "") {
    if (dateString == "") return "";
    const today = new Date();
    const date = DateTimeUtility.newFromString(dateString);
    if (!date) return null;

    const diffTime = date.getTime() - today.getTime();
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    let labelDiffDays = "";

    if (diffDays == 0) {
      labelDiffDays = `-1${t("datetime.d")}${t("datetime.d")}`;
    } else if (diffDays > 0) {
      labelDiffDays = `-${diffDays}${t("datetime.d")}${t("datetime.d")}`;
    } else {
      labelDiffDays = t("leaflets.full_valids_until_expired");
    }

    return labelDiffDays;
  }

  static addDays(date = new Date(), days) {
    const updatedDate = new Date(date);
    updatedDate.setDate(date.getDate() + days);
    return updatedDate;
  }

  static removeDays(date = new Date(), days = 0) {
    const updatedDate = new Date(date);
    updatedDate.setDate(date.getDate() - days);
    return updatedDate;
  }

  static convertForQuery(date = new Date()) {
    return Math.trunc(date.getTime() / 1000);
  }

  static convertDayMonth(date = new Date(), monthLetter = true) {
    try {
      date = typeof date == "string" ? new Date(date) : date;
      if (monthLetter) {
        const { pqConfs } = useSelectorPQGlobalThis();

        return `${date.getDate()} ${date.toLocaleString(pqConfs.language, {
          month: "long",
        })}`;
      } else {
        return `${("0" + date.getDate()).slice(-2)}/${(
          "0" +
          (date.getMonth() + 1)
        ).slice(-2)}`;
      }
    } catch (error) {
      PqConsole.error("convertDayMonth", error);
      return "";
    }
  }

  static isExpired(expired) {
    const now = parseInt(new Date().getTime() / 1000);
    return now > expired;
  }

  static isDayOf(days = [], date = new Date()) {
    if (days.indexOf(date.getDay()) >= 0) {
      return true;
    }
    return false;
  }

  static secondsToMinutes(time) {
    var minutes = Math.trunc(Math.floor(time / 60));
    var seconds = Math.trunc(time - minutes * 60);
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}`;
  }
}

DateTimeUtility.DAYS = {
  MON: 1,
  TUE: 2,
  WED: 3,
  THU: 4,
  FRI: 5,
  SAT: 6,
  SUN: 0,
  WEEKEND: [0, 6],
  WORK: [1, 2, 3, 4, 5],
};

export default DateTimeUtility;
