import "@/styles/globals.scss";
import type { AppProps } from "next/app";

import wrapper from "@/commons/redux/reducers/stores";
import { ApolloProvider } from "@apollo/client";
import { useApollo } from "apollo";

const PqApp = ({ Component, pageProps }: any) => {
  const client = useApollo(pageProps.initialApolloState);
  return (
    <ApolloProvider client={client}>
      <Component {...pageProps} />
    </ApolloProvider>
  );
};

PqApp.getInitialProps = async ({ Component, ctx }) => {
  const pageProps = Component.getInitialProps
    ? await Component.getInitialProps(ctx)
    : {};
  return { pageProps: pageProps };
};

export default wrapper.withRedux(PqApp);
