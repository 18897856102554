export default {
  socialLogin: {
    facebook: false,
    google: false,
  },
  // facebook: {
  //   appID: "257592897749429",
  //   scope: "email, user_location",
  //   pixelId: "1112483635792809",
  // },
  google: {
    clientId:
      "691043485490-0bkie4tpavs054h3t2uk7ed67sfu2uaf.apps.googleusercontent.com",
    scope:
      "https://www.googleapis.com/auth/userinfo.profile \
            https://www.googleapis.com/auth/userinfo.email",
  },
};
