import { BatchHttpLink } from "@apollo/client/link/batch-http/batchHttpLink";

import { setupFetch } from "./setup-fetch";

const batchHttpLink = new BatchHttpLink({
  fetch: setupFetch,
  useGETForQueries: true,
  batchMax: 1,
});

export { batchHttpLink };
