import ccontextEvents from "@/commons/redux/events/ccontextEvents";

const initialState = {
  rStateHeaders: null,
  rStateDeviceUser: null,
  rStateAppRender: null,
  rStateAcceptWebP: null,
  rStateIsBot: null,
  rStateUserIp: null,
  rStateLocationHref: null,
  rStateGoogleAnalytics: null,
  rStateRendertron: null,
};
const ccontextReducers = (state = initialState, action) => {
  switch (action.type) {
    case ccontextEvents.SET_HEADERS:
      state = { ...state, rStateHeaders: action.payload };
      return state;
    case ccontextEvents.SET_DEVICE_USER:
      state = { ...state, rStateDeviceUser: action.payload };
      return state;
    case ccontextEvents.SET_APP_RENDER:
      state = { ...state, rStateAppRender: action.payload };
      return state;
    case ccontextEvents.SET_ACCEPT_WEBP:
      state = { ...state, rStateAcceptWebP: action.payload };
      return state;
    case ccontextEvents.SET_IS_CHROME_LIGHTHOUSE:
      state = { ...state, rStateIsChromeLighthouse: action.payload };
      return state;
    case ccontextEvents.SET_IS_BOT:
      state = { ...state, rStateIsBot: action.payload };
      return state;
    case ccontextEvents.SET_USER_IP:
      state = { ...state, rStateUserIp: action.payload };
      return state;
    case ccontextEvents.SET_LOCATION_HREF:
      state = { ...state, rStateLocationHref: action.payload };
      return state;
    case ccontextEvents.SET_GOOGLE_ANALYTICS:
      state = { ...state, rStateGoogleAnalytics: action.payload };
      return state;
    case ccontextEvents.SET_RENDERTRON:
      state = { ...state, rStateRendertron: action.payload };
      return state;
    default:
      return state;
  }
};

export default ccontextReducers;
export { initialState };
