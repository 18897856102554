import Model from "./Model";

class SocialLinkModel extends Model {
  constructor(socialLink = {}) {
    super(socialLink);
    this._name = socialLink.social;
    this._url = socialLink.url;
  }

  get name() {
    return this._name;
  }
  set name(value) {
    this._name = value;
  }

  get url() {
    return this._url;
  }
  set url(value) {
    this._url = value;
  }

  static getCollection(socialLinks) {
    return socialLinks.map((socialLink) => new SocialLinkModel(socialLink));
  }
}

export default SocialLinkModel;
