import { ApolloClient, InMemoryCache, gql } from "@apollo/client";

import PqConsole from "@/commons/utilities/PqConsole";
import TokenManager from "@/commons/dataset/manager/TokenManager";
import { disableFragmentWarnings } from "graphql-tag";
import includes from "lodash-es/includes";
import { useSelectorPQGlobalThis } from "@/commons/hooks/usePQGlobalThis";

class Queries {
  constructor(urlClient) {
    const { pqConfs } = useSelectorPQGlobalThis();
    this.urlClient = urlClient || pqConfs?.gql.domainGql;
    disableFragmentWarnings();
  }

  printGql(name, gqlQuery, variables) {
    return;
    PqConsole.loqQuery(`\n----------------
    \nquery name: ${name.replace(/(\r\n|\n|\r)/gm, " ").replace(/(  )/gm, " ")}
    \nurlClient: ${this.urlClient}
    \nquery: ${gqlQuery.replace(/(\r\n|\n|\r)/gm, " ").replace(/(  )/gm, " ")}
    \nvariables: ${JSON.stringify(variables)}
    \n----------------`);
  }

  getClient(token) {
    const client = new ApolloClient({
      uri: this.urlClient,
      headers: { authorization: `Bearer ${token.value}` },
      cache: new InMemoryCache(),
    });
    return client;
  }

  getFragments(fragments) {
    if (fragments) {
      return fragments
        .map((fragment) => fragment.gql.loc.source.body)
        .join(" ");
    }
    return "";
  }

  getQuery(name, query, data, fragments = null, variables = {}) {
    fragments = this.getFragments(fragments);
    const gqlQuery = `query ${name}{ ${query}{ ${data} } } ${fragments}`;
    this.printGql(name, gqlQuery, variables);
    const paramsQuery = {
      query: gql`
        ${gqlQuery}
      `,
      variables: variables,
      fetchPolicy: "no-cache",
    };
    return paramsQuery;
  }

  async getTokenForQueries() {
    const token = await TokenManager.getToken();
    return token;
  }

  async getTokenRetryQuery() {
    const token = await TokenManager.refreshToken();
    return token;
  }

  execQuery(
    name,
    query,
    data,
    fragments = null,
    variables = {},
    returnJson = false,
  ) {
    return new Promise(async (resolve, reject) => {
      const paramsQuery = this.getQuery(
        name,
        query,
        data,
        fragments,
        variables,
      );

      try {
        const token = await this.getTokenForQueries();
        const client = this.getClient(token);
        const resultQuery = await client.query(paramsQuery);
        if (resultQuery) {
          const parseResultQuery = this.getResult(resultQuery, returnJson);
          resolve(parseResultQuery);
        } else {
          reject(`PQ ERROR: ${name} result not found`);
        }
      } catch (error) {
        if (this.isRetryError(error)) {
          try {
            const token = await this.getTokenRetryQuery();
            const client = this.getClient(token);
            const resultQuery = await client.query(paramsQuery);
            if (resultQuery) {
              const parseResultQuery = this.getResult(resultQuery, returnJson);
              resolve(parseResultQuery);
            } else {
              reject(new Error(`query error: ${name}`));
            }
          } catch (error) {
            reject(error);
          }
        } else {
          reject(error);
        }
      }
    });
  }

  isRetryError(error) {
    return includes([401, 403], error?.networkError?.statusCode);
  }

  getResult(result, returnJson = false) {
    throw new Error(
      "PQ ERROR: implementare il metodo getResult nella classe di Queries",
    );
  }
}

export default Queries;
