const ImageSizeConstants = {
  SMALL: "small",
  NORMAL: "normal",
  BOXINFO: "box_info",
  THUMB: "thumb",
  PREVIEW: "preview",
  MEDIUM: "medium",
  LARGE: "large",
  MEDIUM_LARGE: "medium_large",
  PAGE_PREVIEW: "page_preview",
  ORIGINAL: "original",
  PIN: "pin",
};

export { ImageSizeConstants };
