import includes from "lodash-es/includes";
import { createWrapper } from "next-redux-wrapper";
import { configureStore, Store } from "@reduxjs/toolkit";
import counterReducers, {
  initialState as initalStateCounter,
} from "./counterReducers";
import locationReducers, {
  initialState as initalStateLocation,
} from "./locationReducers";
import rdxUserReducers, {
  initialState as initalStateUser,
  RdxUserState,
} from "./rdxUserReducers";
import ccontextReducers, {
  initialState as initalStateCContext,
} from "./ccontextReducers";
import commentsReducers, {
  initialState as initalStateComments,
} from "./commentsReducers";

import { counterKeyEvents } from "@/commons/redux/events/counterEvents";
import { locationKeyEvents } from "@/commons/redux/events/locationEvents";
import { rdxUserKeyEvents } from "@/commons/redux/events/rdxUserEvents";
import { ccontextKeyEvents } from "@/commons/redux/events/ccontextEvents";
import { commentsKeyEvents } from "@/commons/redux/events/commentsEvents";

import { HYDRATE } from "next-redux-wrapper";
import { CommentsReducerState } from "./commentsReducers/typings";

interface CounterState {
  value: number;
}

interface ReducerState {
  counter: CounterState;
  location: any;
  rdxUser: RdxUserState;
  ccontext: any;
  comments: CommentsReducerState;
}

const initalState: ReducerState = {
  counter: initalStateCounter,
  location: initalStateLocation,
  rdxUser: initalStateUser,
  ccontext: initalStateCContext,
  comments: initalStateComments,
};

const allReducers = (state = initalState, action): ReducerState => {
  const { type } = action;
  if (type == HYDRATE) {
    return { ...state, ...action.payload };
  } else {
    if (includes(type, counterKeyEvents)) {
      return { ...state, counter: counterReducers(state.counter, action) };
    } else if (includes(type, locationKeyEvents)) {
      return { ...state, location: locationReducers(state.location, action) };
    } else if (includes(type, rdxUserKeyEvents)) {
      return { ...state, rdxUser: rdxUserReducers(state.rdxUser, action) };
    } else if (includes(type, ccontextKeyEvents)) {
      return { ...state, ccontext: ccontextReducers(state.ccontext, action) };
    } else if (includes(type, commentsKeyEvents)) {
      return { ...state, comments: commentsReducers(state.comments, action) };
    } else {
      return state;
    }
  }
};

let storeRedux: Store;
const makeStore = (context) => {
  storeRedux = configureStore({ reducer: allReducers });
  return storeRedux;
};

const wrapper = createWrapper(makeStore, { debug: false });

type RootState = ReturnType<typeof allReducers>;

export type { RootState };

export { storeRedux };
export default wrapper;
