import CategoryModel from "./CategoryModel";
import ImageModel from "./ImageModel";
import LoyaltyCardModel from "./LoyaltyCardModel";
import Model from "./Model";
import SectionModel from "./SectionModel";

const setSection = (retailer = {}) => {
  return retailer.section ? new SectionModel(retailer.section) : null;
};

const setParent = (retailer = {}) => {
  return retailer.parent ? new RetailerModel(retailer.parent) : null;
};

const setLoyaltyCards = (retailer = {}) => {
  return retailer.loyalty_cards
    ? LoyaltyCardModel.getCollection(retailer.loyalty_cards)
    : null;
};

const setCategories = (retailer = {}) => {
  return retailer.categories
    ? CategoryModel.getCollection(retailer.categories)
    : null;
};

const setAssociatedCategories = (retailer = {}) => {
  return retailer.associated_categories
    ? CategoryModel.getCollection(retailer.associated_categories)
    : null;
};

const setLogo = (retailer = {}, ext = "") => {
  return retailer[`logo${ext}`] ? new ImageModel(retailer[`logo${ext}`]) : null;
};

class RetailerModel extends Model {
  constructor(retailer = {}) {
    super(retailer);
    this.name = retailer.name;
    this.slug = retailer.slug;
    this.isNational = retailer.national;
    this.isCompact = retailer.compact;
    this.typename = retailer.__typename;
    this.localDescription = retailer.local_description;
    this.nationalDescription = retailer.national_description;
    this.topCategoriesNames = retailer.top_categories_names;
    this.adblock = retailer.adblock;
    this.section = setSection(retailer);
    this.parent = setParent(retailer);
    this.loyaltyCards = setLoyaltyCards(retailer);
    this.categories = setCategories(retailer);
    this.associatedCategories = setAssociatedCategories(retailer);

    this.seoBodyTitle = retailer.seo_body_title;
    this.seoBodyNationalTitle = retailer.seo_body_national_title;

    //--- logo ---
    this.logo = setLogo(retailer, "");
    this.logoWebP = setLogo(retailer, "_webp");
    this.logoWebPSupportedFormats = retailer.logo_webp_supported_formats;
    //------------

    this.formats = this.logoWebPSupportedFormats?.map((format) => {
      return {
        type: format,
        url: this.logoWebP.imgFormat.replace(":FORMAT", format),
      };
    });

    this.pageAdBlock = retailer.page_adblock;
    this.excludeCatSimilarLeaflet = retailer.exclude_cat_similar_leaflet;

    this.metaTitle = retailer.meta_title;
    this.metaNationalTitle = retailer.meta_national_title;
    this.metaDescription = retailer.meta_description;
    this.metaNationalDescription = retailer.meta_national_description;

    this.hasLocalAmp = retailer.amp_local;
    this.hasNationalAmp = retailer.amp;

    this.shopfullySendEvents =
      retailer.shopfully_send_events || retailer.shopfullySendEvents;
    this.excludeRating =
      retailer.shopfully_is_premium || retailer.excludeRating || false;
    this.shopfullyIsPremium =
      retailer.shopfully_is_premium || retailer.shopfullyIsPremium || false;
  }

  get existsLogo() {
    return this.logo !== null || this.logoWebP != null;
  }

  static getCollection = (data) => data.map((d) => new RetailerModel(d));
}

export default RetailerModel;
