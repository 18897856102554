import { useSelector } from "react-redux";
import UserModel from "@/commons/dataset/models/UserModel";
import { CookieKeys } from "../keys";
import { getCookie, setCookie, deleteCookie } from "cookies-next";

class UserUtility {
  // Set the PQUSER cookie
  static login(userData, params = {}) {
    const userDataForCookie = UserUtility.modelToCookie(userData);
    const userEncoded = UserUtility.encodeUserCookie(userDataForCookie);
    setCookie(CookieKeys.USER, userEncoded, {
      ...params,
      maxAge: 365 * 24 * 60 * 60,
    });
  }

  // Delete the PQUSER cookie
  static logout(params = {}) {
    deleteCookie(CookieKeys.USER, params);
  }

  // Check if the PQUSER cookie exists
  static isUserLogin(params = {}) {
    try {
      if (getCookie(CookieKeys.USER, params)) {
        return true;
      }
    } catch (error) {
      return false;
    }

    return false;
  }

  // Get userData from the PQUSER cookie
  static getUserData(params = {}): UserModel {
    try {
      const userCookie = getCookie(CookieKeys.USER, params);
      const userData = JSON.parse(atob(userCookie as string));

      return UserUtility.modelFromCookie(userData);
    } catch (error) {
      return null;
    }
  }

  // Get userDataModel from the PQUSER cookie
  static getUserDataModel(params = {}) {
    const userData = UserUtility.getUserData(params);

    if (userData) {
      return new UserModel(userData);
    }

    return null;
  }

  static encodeUserCookie(userData) {
    const userEncoded = btoa(JSON.stringify(userData));
    return userEncoded;
  }

  static decodeUserCookie(userCookie) {
    try {
      let userDecoded = atob(userCookie);
      userDecoded = JSON.parse(userDecoded);
      return userDecoded;
    } catch (error) {
      return null;
    }
  }

  static modelToCookie(userData) {
    let result: any = {};
    result._id = userData.id || userData._id;
    result._name = userData.name || userData._name || "";
    result._username = userData.username || userData._username || "";
    result._surname = userData.surname || userData._surname || "";
    result._gender = userData.gender || userData._gender || "";
    result._email = userData.email || userData._email || "";
    result._latitude = userData.latitude || userData._latitude || "";
    result._longitude = userData.longitude || userData._longitude || "";
    result._locationText =
      userData.location_text || userData._locationText || "";
    result._facebookID =
      userData.facebook_id || userData._facebookID || userData.facebookID || "";
    result._facebookImg =
      userData.facebook_img ||
      userData._facebookImg ||
      userData.facebookImg ||
      "";
    result._token =
      userData.jwt_token || userData._token || userData.token || "";
    result._tokenExpires =
      userData.jwt_token_expires ||
      userData._tokenExpires ||
      userData.tokenExpires ||
      "";

    return result;
  }

  static modelFromCookie(userDataFromCookie: any): any {
    const result = {
      id: userDataFromCookie._id,
      name: userDataFromCookie._name,
      username: userDataFromCookie._username,
      surname: userDataFromCookie._surname,
      gender: userDataFromCookie._gender,
      email: userDataFromCookie._email,
      latitude: userDataFromCookie._latitude,
      longitude: userDataFromCookie._longitude,
      locationText: userDataFromCookie._locationText,
      facebookID: userDataFromCookie._facebookID,
      facebookImg: userDataFromCookie._facebookImg,
      token: userDataFromCookie._token,
      tokenExpires: userDataFromCookie._tokenExpires,
    };

    return result;
  }
}

/* UserUtility.SOCIAL_PROVIDER = {
  FACEBOOK: "facebook",
  GOOGLE: "google",
  GOOGLE_ONE_TAP: "google_one_tap",
  APPLE: "apple",
}; */

export default UserUtility;
