export default {
  columns: {
    col1: true,
    col2: true,
    col3: true,
    col4Local: true,
    col4National: true,
  },
  links: {
    corporate: {
      active: true,
      link: "https://corporate.shopfully.com/it/",
    },
    whoWeAre: {
      active: true,
      link: "https://corporate.shopfully.com/it/chi-siamo/",
    },
    workingWithUs: {
      active: true,
      link: "https://corporate.shopfully.com/it/lavora-con-noi/",
    },
    corporateContactUs: {
      active: true,
      link: "https://corporate.shopfully.com/it/contatti/",
    },
    who: {
      active: true,
    },
    how: {
      active: true,
    },
    app: {
      active: true,
    },
    eco: {
      active: true,
    },
    smart: {
      active: true,
    },
    agencies: {
      active: false,
    },
    contactUs: {
      active: true,
    },
    blog: {
      active: false,
    },
    allStores: {
      activeLocal: true,
      activeNational: true,
    },
    allShoppingCenters: {
      activeLocal: true,
      activeNational: true,
    },
    allBrands: {
      activeLocal: true,
      activeNational: true,
    },
    allCategories: {
      activeLocal: true,
      activeNational: true,
    },
    allOpenStores: {
      activeLocal: true,
      activeNational: true,
    },
    allRetailers: {
      activeLocal: true,
      activeNational: true,
    },
    allLoyaltyCards: {
      activeLocal: true,
      activeNational: true,
    },
    allCities: {
      activeLocal: true,
      activeNational: true,
    },
  },
};
