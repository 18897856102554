const RoutesKeys = {
  HOME_PAGE: "homePage",
  PASSWORD_RECOVERY: "passwordRecovery",
  HOME_PAGE_LOCAL: "homePageLocal",
  LEAFLET_PREVIEW: "leafletPreview",
  LEAFLET_PREVIEW_LOCAL: "leafletPreviewLocal",
  LEAFLET: "leaflet",
  LEAFLET_WITH_PAGE: "leafletWithPage",
  LEAFLET_WITH_OFFER: "leafletWithOffer",
  LEAFLET_WITH_OFFER_ID: "leafletWithOfferId",
  LEAFLET_WITH_ONLY_OFFER_ID: "leafletWithOnlyOfferId",
  LEAFLET_WITH_CATEGORY_LABEL: "leafletWithCategoryLabel",
  RETAILER: "retailer",
  RETAILER_LOCAL: "retailerLocal",
  RETAILER_AMP: "retailerAmp",
  RETAILER_LOCAL_AMP: "retailerLocalAmp",
  OFFER: "offer",
  OFFER_LOCAL: "offerLocal",
  OFFER_CATEGORY: "offerCategory",
  OFFER_CATEGORY_LOCAL: "offerCategoryLocal",
  OFFER_RETAILER_CATEGORY: "offerRetailerCategory",
  OFFER_PREFERRED_LEAFLETS: "offerPreferredLeaflets",
  PRODUCT: "product",
  SEARCH: "search",
  SEARCH_MODEL: "searchModel",
  SHOPPING_CENTER: "shoppingCenter",
  STORE: "store",
  STORE_WITH_SINGLE_PATH: "storeWithSinglePath",
  STORE_RETAILER: "storeRetailer",
  STORE_RETAILER_LOCAL: "storeRetailerLocal",
  NATIONAL_STORE: "nationalStore",
  SHOPPING_LIST: "shoppingList",
  SHOPPING_LIST_SHARE: "shoppingListShare",
  PREFERENCES_REGISTRATION: "preferencesRegistration",
  PREFERENCES_STORES: "preferencesStores",
  PREFERENCES_PRODUCTS: "preferencesProducts",
  PREFERENCES_CATEGORIES: "preferencesCategories",
  PREFERENCES_MANAGE: "preferencesManage",
  LOCALITA: "localita",
  LOYALTY_CARD: "loyaltyCard",
  INDEX_CATEGORIES: "indexCategories",
  INDEX_CATEGORIES_LOCAL: "indexCategoriesLocal",
  INDEX_STORES: "indexStores",
  INDEX_SHOPPING_CENTERS: "indexShoppingCenters",
  REQUEST_PERSONAL_DATA: "requestPersonalData",
  MOBILE_APP: "mobileApp",
  WHO: "who",
  HOW: "how",
  ECO: "eco",
  BLOG: "blog",
  ALL_BRANDS: "allBrands",
  ALL_RETAILERS: "allRetailers",
  ALL_RETAILERS_RANGED: "allRetailersRanged",
  ALL_RETAILERS_LOCAL: "allRetailersLocal",
  ALL_CATEGORIES: "allCategories",
  ALL_CATEGORIES_LOCAL: "allCategoriesLocal",
  ALL_LOYALTY_CARDS: "allLoyaltyCards",
  ALL_CITIES: "allCities",
  ALL_CITIES_RANGED: "allCitiesRanged",
  ALL_OPEN_STORES: "allOpenStores",
  ALL_OPEN_STORES_LOCAL: "allOpenStoresLocal",
  ALL_SHOPPING_CENTERS: "allShoppingCenters",
  TERMS_AND_CONDITIONS: "termsAndConditions",
  PRIVACY: "privacy",
  REQUEST_PERSONAL_DATA: "requestPersonalData",
};

export default RoutesKeys;
