import Model from "./Model";
// import RetailerModel from './RetailerModel';
import ImageModel from "./ImageModel";

const setImage = (loyaltyCard = {}, field, ext = "") => {
  return loyaltyCard[`${field}${ext}`]
    ? new ImageModel(loyaltyCard[`${field}${ext}`])
    : null;
};

class LoyaltyCardModel extends Model {
  constructor(loyaltyCard = {}) {
    super(loyaltyCard);

    this.name = loyaltyCard.name;
    this.slug = loyaltyCard.slug;

    //--- frontImage / backImage ---
    this.frontImage = setImage(loyaltyCard, "front_image", "");
    this.frontImageWebP = setImage(loyaltyCard, "front_image", "_webp");
    this.frontImageWebPSupportedFormats =
      loyaltyCard.front_image_webp_supported_formats;

    this.backImage = setImage(loyaltyCard, "back_image", "");
    this.backImageWebP = setImage(loyaltyCard, "back_image", "_webp");
    this.backImageWebPSupportedFormats =
      loyaltyCard.back_image_webp_supported_formats;
    //------------

    this.url = loyaltyCard.url;
    this.description = loyaltyCard.description;
    this.active = loyaltyCard.active;

    // this.retailers = loyaltyCard.retailers ? RetailerModel.getCollection(loyaltyCard.retailers) : null;
  }

  static getCollection = (data) => data.map((d) => new LoyaltyCardModel(d));
}

export default LoyaltyCardModel;
