const commons = {
  firebase: {
    serviceWorker: "/workers/firebase-messaging-sw.js",
    apiKey: "AIzaSyD7yHUat5tDFUM-RlOOFXKWtmYcsjnQy2M",
    authDomain: "admob-app-id-5228366264.firebaseapp.com",
    databaseURL: "https://admob-app-id-5228366264.firebaseio.com",
    projectId: "admob-app-id-5228366264",
    storageBucket: "admob-app-id-5228366264.appspot.com",
    messagingSenderId: "691043485490",
    appId: "1:691043485490:web:e8da66141e097ec663f92b",
    measurementId: "G-V7H9NB5H8S",
    serverKey:
      "BIM9ABynh8D-YP0PtXKNLX3V3srOK6mS6wAIdNzg0CzPQtG7nhvSkr_Jp2MpKx5_NzKclepE03EGyDF6DkwhJfc",
  },
  tracking: {
    pq: {
      url: "https://services.promoqui.it/tracking/trackEvent",
      xApiKey: "qdWGCkHC263El2eoZK02w6NN9owmhsHc35q9SJKl",
    },
    fb: {
      pixelId: "1112483635792809",
    },
  },
};

export default commons;
