import getConfs from "@/commons/confs";
import UserUtility from "@/commons/utilities/UserUtility";
import { AuthenticatedOperations } from "./authenticated-operations";
//import { getUriByCountry } from "~/constants/uris";
//import { AuthenticatedOperations } from "~/enums/operations";
//import { Uri } from "~/enums/uri";
//import { UserService } from "~/services/user-service";
//import { isAuthenticatedOperation } from "~/utils/is-authenticated-operation";

const AppAndAuthenticatedOperations = {
  GET_SHOPPING_LISTS: "getShoppingLists",
};

const isAppAndAuthenticatedOperation = (
  operationName: AuthenticatedOperations | string, // AppAndAuthenticatedOperations,
): boolean => {
  const appAndAuthenticatedOperations = Object.values(
    AppAndAuthenticatedOperations,
  );

  const appAndAuthenticated =
    appAndAuthenticatedOperations.includes(operationName);

  if (appAndAuthenticated) {
    const user = UserUtility.getUserData();
    return Boolean(user);
  }

  return false;
};

export const isAuthenticatedOperation = (
  operationName?: AuthenticatedOperations,
): boolean => {
  const authenticatedOperations = Object.values(AuthenticatedOperations);
  if (!operationName) return false;

  const isAppAndAuthenticated = isAppAndAuthenticatedOperation(operationName);

  if (isAppAndAuthenticated) {
    return true;
  }

  return authenticatedOperations.includes(
    operationName as AuthenticatedOperations,
  );
};

export enum Uri {
  GRAPHQL = "graphql",
  USER_AUTH_SUFFIX = "authenticated",
  APP_AUTH_SUFFIX = "appauth",
}

const getUriByCountry = async (): Promise<string> => {
  return `https://apiv3.promoqui.it/graphql`;
};

const setupFetch = async (
  _input: RequestInfo,
  options?: RequestInit,
): Promise<Response> => {
  /* const { isInternetReachable } = await NetInfo.fetch()
	if (!isInternetReachable) throw new Error('Client is offline.') */

  //const countryUri = await getUriByCountry();

  const { gql } = getConfs(process.env.NEXT_PUBLIC_COUNTRY);
  let operationName = null;
  let uri = gql.domainGql;

  const body = JSON.parse(options?.body as string);
  if (Array.isArray(body) && body.length > 0) {
    operationName = body[0] ? body[0].operationName : null;
  } else if (typeof body === "object") operationName = body.operationName;

  const isAuthOperation = isAuthenticatedOperation(
    operationName as AuthenticatedOperations,
  );

  const isUserLogin = UserUtility.isUserLogin();

  if (operationName && isAuthOperation && isUserLogin) {
    uri = `${uri}/${Uri.USER_AUTH_SUFFIX}`;
  } else if (operationName === "getAuthToken")
    uri = `${uri}/${Uri.APP_AUTH_SUFFIX}`;

  return fetch(uri, options);
};

export { setupFetch };
