import Model from "./Model";
class UserModel extends Model {
  name: string;
  username: string;
  surname: string;
  gender: string;
  email: string;
  latitude: string;
  longitude: string;
  locationText: string;
  facebookID: string;
  facebookImg: string;
  token: string;
  tokenExpires: string;
  constructor(userData: any = {}) {
    super(userData);
    //userData._name dovuto al cookie presente sul vecchio applicativo
    this.name = userData.name || userData._name;
    this.username = userData.username || userData._username;
    this.surname = userData.surname || userData._surname;
    this.gender = userData.gender || userData._gender;
    this.email = userData.email || userData._email;
    this.latitude = userData.latitude || userData._latitude;
    this.longitude = userData.longitude || userData._longitude;
    this.locationText = userData.location_text || userData._locationText;
    this.facebookID = userData.facebook_id || userData._facebookID;
    this.facebookImg = userData.facebook_img || userData._facebookImg;
    this.token = userData.jwt_token || userData.token || userData._token;
    this.tokenExpires =
      userData.jwt_token_expires ||
      userData.tokenExpires ||
      userData._tokenExpires;
  }

  get usernameFully(): string {
    let username = "";

    if (this.name && this.name != "") {
      username = this.name;
    }

    if (
      this.surname &&
      this.surname != "" &&
      username.indexOf(this.surname) < 0
    ) {
      username += ` ${this.surname}`;
    }

    if (username != "") {
      return username;
    } else if (this.email && this.email != "") {
      return this.email.split("@")[0];
    }
    return this.username;
  }

  get usernameFullyWithEmail(): string {
    if (this.email) {
      if (this.usernameFully && this.usernameFully != "") {
        return `${this.usernameFully} (${this.email})`;
      } else {
        return this.email;
      }
    } else {
      return this.usernameFully;
    }
  }

  get usernameInitial(): string {
    let name = this.usernameFully;
    return name.substring(0, 1);
  }

  static getCollection(users) {
    return users.map((userData) => new UserModel(userData));
  }
}

export default UserModel;

/*
[  {
      "domain": "localhost",
      "expirationDate": 1683706310,
      "hostOnly": true,
      "httpOnly": false,
      "name": "PQUSER",
      "path": "/",
      "sameSite": "unspecified",
      "secure": false,
      "session": false,
      "storeId": "0",
      "value": "eyJfaWQiOjg4Mzc5LCJfbmFtZSI6Ikdpb3Zhbm5pIEJlbGZpb3JlIiwiX3VzZXJuYW1lIjoiR2lvdmFubmkgQmVsZmlvcmUgQmVsZmlvcmUiLCJfc3VybmFtZSI6IkJlbGZpb3JlIiwiX2VtYWlsIjoiYmVsZmlvcmUuZ2lvdmFubmlAZ21haWwuY29tIiwiX2ZhY2Vib29rSUQiOiIxMDIxNDIzMDc3MTc1NTAzNiIsIl9mYWNlYm9va0ltZyI6Imh0dHBzOi8vZ3JhcGguZmFjZWJvb2suY29tLzEwMjE0MjMwNzcxNzU1MDM2L3BpY3R1cmU/dHlwZT1ub3JtYWwiLCJfdG9rZW4iOiJleUowZVhBaU9pSktWMVFpTENKaGJHY2lPaUpJVXpJMU5pSjkuZXlKcGMzTWlPaUpvZEhSd09pOHZZWEJwZGpNdWNISnZiVzl4ZFdrdWFYUXZaM0poY0doeGJDSXNJbWxoZENJNk1UWTFNakUzTURNeE1Dd2laWGh3SWpveE5qVXlNVGMzTlRFd0xDSnVZbVlpT2pFMk5USXhOekF6TVRBc0ltcDBhU0k2SWxoNVVEQlZlSEJEVEVGNWVUQXdSRmNpTENKemRXSWlPamc0TXpjNUxDSndjbllpT2lJNE4yVXdZV1l4WldZNVptUXhOVGd4TW1aa1pXTTVOekUxTTJFeE5HVXdZakEwTnpVME5tRmhJbjAudGkxZ21COU1zQXQybzI2aEZUWXU3bnNJWkhnblgzRnFJTFNCSUQ1U2xKZyIsIl90b2tlbkV4cGlyZXMiOjE2NTIxNzc1MTB9",
      "id": 27
  }]
*/
