import Mutations from "./Mutations";
import UserModel from "@/commons/dataset/models/UserModel";
import UserFragments from "@/commons/dataset/fragments/UserFragments";

class TokenUserMutations extends Mutations {
  getResult(result, returnJson = false) {
    if (result.data) {
      if (returnJson) {
        return result.data.tokenUser;
      } else {
        return new UserModel(result.data.tokenUser);
      }
    }
    return null;
  }

  renewal(params) {
    const defaults = { token: null, returnJson: false };
    params = { ...defaults, ...params };
    const { token, returnJson } = params;
    const name = "TokenUser_renewal($token:String!)";
    const fragment = UserFragments.userSocial();
    const data = `...${fragment.name}`;
    const mutation = `tokenUser:JwtUserRenewal(token:$token)`;
    const variables = { token };
    return this.execMutation(
      name,
      mutation,
      data,
      [fragment],
      variables,
      returnJson,
    );
  }
}

export default TokenUserMutations;
