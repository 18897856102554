export default {
  homePage: "/",
  homePageLocal: "/%{city}",
  leafletPreview: "/new-catalogues-preview",
  leafletPreviewLocal: "/%{city}/new-catalogues-preview",
  leaflet: "/catalogue/%{retailer}/%{id}",
  leafletWithPage: "/catalogue/%{retailer}/%{id}#p=%{page}",
  leafletWithOffer: "/catalogue/%{retailer}/%{id}#p=%{page}&o=%{selectionArea}",
  leafletWithOfferId:
    "/catalogue/%{retailer}/%{id}%{divisorUrl}p=%{page}&oid=%{offerId}",
  leafletWithOnlyOfferId:
    "/catalogue/%{retailer}/%{id}%{divisorUrl}oid=%{offerId}",
  leafletWithCategoryLabel:
    "/catalogue/%{retailer}/%{id}%{divisorUrl}category=%{category}",
  retailer: "/%{section}/%{slug}",
  retailerLocal: "/%{city}/%{section}/%{slug}",
  retailerAmp: "/%{section}/%{slug}/amp",
  retailerLocalAmp: "/%{city}/%{section}/%{slug}/amp",
  offer: "/offers/%{slug}",
  offerLocal: "/%{city}/offers/%{slug}",
  offerCategory: "/offers/%{category}",
  offerCategoryLocal: "/%{city}/offers/%{category}",
  offerRetailerCategory: "/offers/%{retailer}/%{category}",
  offerPreferredLeaflets: "/offers/preferred-catalogues",
  product: "/products/%{category}/%{product}",
  search: "/search?q=%{key}",
  searchModel: "/search?q=%{key}&model=1",
  shoppingCenter: "/%{city}/%{section}/%{slug}",
  store: "/%{city}/%{section}/%{slug}",
  storeRetailer: "/stores/%{slug}",
  storeRetailerLocal: "/%{city}/stores/%{slug}",
  nationalStore: "/stores/%{slug}",
  shoppingList: "/preferences/shopping-list",
  shoppingListShare: "/preferences/share_shopping_list/%{shareHash}",
  preferencesRegistration: "/preferences/registration",
  preferencesStores: "/preferences/stores",
  preferencesProducts: "/preferences/products",
  preferencesCategories: "/preferences/categories",
  preferencesManage: "/preferences/manage",
  localita: "/location/%{firstLetter}",
  loyaltyCard: "/loyalty-card/%{slug}",
  indexCategories: "/offers-leaflet/%{firstLetter}",
  indexCategoriesLocal: "/%{city}/offers-leaflet.%{firstLetter}",
  indexStores: "/stores/%{firstLetter}",
  indexShoppingCenters: "/shopping-center/%{firstLetter}",
  requestPersonalData: "/preferences/request-personal-data",
  mobileApp: "/mobile-app",
  retailers: "/stores",
  who: "/who-we-are",
  how: "/how-it-works",
  eco: "/eco",
  blog: "/blog",
  allBrands: "/brands",
  allCategories: "/offers-leaflet",
  allCategoriesLocal: "/%{city}/offers-leaflet",
  allLoyaltyCards: "/loyalty-card",
  allCities: "/location",
  allCitiesRanged: "/location/%{range}",
  allOpenStores: "/stores/open-now",
  allOpenStoresLocal: "/%{city}/stores/open-now",
  allRetailers: "/stores",
  allRetailersRanged: "/stores/%{range}",
  allRetailersLocal: "/%{city}/stores",
  allShoppingCenters: "/shopping-center",
  termsAndConditions: "/terms-and-conditions",
  privacy: "/privacy",
};
