import { TypePolicy } from "@apollo/client";

const SeoInfo: TypePolicy = {
  fields: {
    formats: {
      read(_, { readField }) {
        let formats = [];
        const supportedFormats = readField(
          "image_webp_supported_formats",
        ) as string[];
        const logoWebP = readField("image_webp") as string;

        if (logoWebP) {
          supportedFormats.forEach((f) => {
            formats.push({
              type: f,
              url: logoWebP.replace(":FORMAT", f),
            });
          });
        }

        return formats;
      },
    },
  },
};

export { SeoInfo };
