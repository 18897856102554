import counterEvents from "@/commons/redux/events/counterEvents";

const initialState = { value: 0 };
const counterReducers = (state = initialState, action) => {
  switch (action.type) {
    case counterEvents.INIT:
      state = { ...state, value: 0 };
      return state;
    case counterEvents.INC:
      state = { ...state, value: state.value + 1 };
      return state;
    case counterEvents.DEC:
      state = { ...state, value: state.value - 1 };
      return state;
    default:
      return state;
  }
};

export default counterReducers;
export { initialState };
