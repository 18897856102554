export default {
  homePage: "/",
  homePageLocal: "/%{city}",
  leafletPreview: "/cataloage-in-avampremiera",
  leafletPreviewLocal: "/%{city}/cataloage-in-avampremiera",
  leaflet: "/catalog/%{retailer}/%{id}",
  leafletWithPage: "/catalog/%{retailer}/%{id}#p=%{page}",
  leafletWithOffer: "/catalog/%{retailer}/%{id}#p=%{page}&o=%{selectionArea}",
  leafletWithOfferId:
    "/catalog/%{retailer}/%{id}%{divisorUrl}p=%{page}&oid=%{offerId}",
  leafletWithOnlyOfferId:
    "/catalog/%{retailer}/%{id}%{divisorUrl}oid=%{offerId}",
  leafletWithCategoryLabel:
    "/catalog/%{retailer}/%{id}%{divisorUrl}category=%{category}",
  retailer: "/%{section}/%{slug}",
  retailerLocal: "/%{city}/%{section}/%{slug}",
  retailerAmp: "/%{section}/%{slug}/amp",
  retailerLocalAmp: "/%{city}/%{section}/%{slug}/amp",
  offer: "/oferte/%{slug}",
  offerLocal: "/%{city}/oferte/%{slug}",
  offerCategory: "/oferte/%{category}",
  offerCategoryLocal: "/%{city}/oferte/%{category}",
  offerRetailerCategory: "/oferte/%{retailer}/%{category}",
  offerPreferredLeaflets: "/oferte/cataloage-favorabile",
  product: "/produse/%{category}/%{product}",
  search: "/search?q=%{key}",
  searchModel: "/search?q=%{key}&model=1",
  shoppingCenter: "/%{city}/%{section}/%{slug}",
  store: "/%{city}/%{section}/%{slug}",
  storeRetailer: "/magazine/%{slug}",
  storeRetailerLocal: "/%{city}/magazine/%{slug}",
  nationalStore: "/magazine/%{slug}",
  shoppingList: "/preferinte/lista-cumparaturi",
  shoppingListShare: "/preferinte/share_shopping_list/%{shareHash}",
  preferencesRegistration: "/preferinte/inregistrare",
  preferencesStores: "/preferinte/magazine",
  preferencesProducts: "/preferinte/produse",
  preferencesCategories: "/preferinte/categorii",
  preferencesManage: "/preferinte/manage",
  localita: "/localitate/%{firstLetter}",
  loyaltyCard: "/carduri-de-fidelitate/%{slug}",
  indexCategories: "/oferte-catalog/%{firstLetter}",
  indexCategoriesLocal: "/%{city}/oferte-catalog.%{firstLetter}",
  indexStores: "/magazine/%{firstLetter}",
  indexShoppingCenters: "/mall/%{firstLetter}",
  requestPersonalData: "/preferinte/solicitati-date-personale",
  mobileApp: "/mobile-app",
  retailers: "/magazine",
  who: "/cine-suntem",
  how: "/cum-functioneaza",
  eco: "/eco",
  blog: "/blog",
  allBrands: "/marci",
  allCategories: "/oferte-catalog",
  allCategoriesLocal: "/%{city}/oferte-catalog",
  allLoyaltyCards: "/carduri-de-fidelitate",
  allCities: "/localitate",
  allCitiesRanged: "/localitate/%{range}",
  allOpenStores: "/magazine/aperti-oggi",
  allOpenStoresLocal: "/%{city}/magazine/aperti-oggi",
  allRetailers: "/magazine",
  allRetailersRanged: "/magazine/%{range}",
  allRetailersLocal: "/%{city}/magazine",
  allShoppingCenters: "/mall",
  termsAndConditions: "/terms-and-conditions",
  privacy: "/privacy",
};
