import Model from "./Model";

class SectionModel extends Model {
  constructor(section = {}) {
    super(section);
    this.name = section.name;
    this.slug = section.slug;
  }
}

export default SectionModel;
