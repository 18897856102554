const { yellow, blue, red, green, white, magentaBright } = require("chalk");
const Sentry = require("@sentry/nextjs");
const includes = require("lodash/includes");

class PqConsole {
  static print(label, data, printError = false) {
    console.log(label);
    if (data) {
      if (printError) {
        console.log(white(data));
      } else {
        console.log(white(JSON.stringify(data)));
      }
    }
    if (printError) {
      console.trace();
    }
    console.log(`\n\n`);
  }

  static log(label, data = null) {
    label = green(`[PQ LOG]: ${label}`);
    PqConsole.print(label, data);
  }

  static info(label, data = null) {
    label = blue(`[PQ INFO]: ${label}`);
    PqConsole.print(label, data);
  }

  static error(label, data = null) {
    label = red(`[PQ ERROR]: ${label}`);
    PqConsole.print(label, data, true);

    const enableSentryOnConsoleError = false;
    if (enableSentryOnConsoleError) {
      // Set user information, as well as tags and further extras
      Sentry.configureScope((scope) => {
        scope.setExtra("PqConsole.label", label);
        scope.setExtra("PqConsole.data", data);
        scope.setExtra("PqConsole.data?.stack", data?.stack);
        if (data?.stack) {
          // is a catched error
          // from token renewal
          const isRetryError = includes(
            [401, 403],
            data?.networkError?.statusCode,
          );
          // from devices not supporting firebase
          const isServiceNotAvailable = (data?.message || "").includes(
            "Service messaging is not available",
          );

          if (!isRetryError && !isServiceNotAvailable) {
            Sentry.captureException(data);
          }
        } else {
          Sentry.captureMessage(label);
        }
      });

      Sentry.configureScope((scope) => {
        scope.clear();
      });
    }
  }

  static warning(label, data = null) {
    label = yellow(`[PQ WARNING]: ${label}`);
    PqConsole.print(label, data);
  }

  static warn(label, data = null) {
    PqConsole.warning(label, data);
  }

  static loqQuery(label, data = null) {
    label = magentaBright(`[PQ QUERY]: ${label}`);
    PqConsole.print(label, data);
  }
}

module.exports = PqConsole;
