const CountryKeys = {
  AU: "au",
  ES: "es",
  IT: "it",
  RO: "ro",
  UK: "uk",
  STG: "stg",
};

export default CountryKeys;
