const rdxUserKeyEvents = "STORE_USER_";
const rdxUserEvents = {
  INIT: `${rdxUserKeyEvents}INIT`,
  LOGOUT: `${rdxUserKeyEvents}LOGOUT`,
  LOGIN: `${rdxUserKeyEvents}LOGIN/SIGNIN`,
  FACEBOOK_LOGIN: `${rdxUserKeyEvents}LOGIN/FACEBOOK_LOGIN`,
  GOOGLE_LOGIN: `${rdxUserKeyEvents}LOGIN/GOOGLE_LOGIN`,
  SET_SHOW_LOGIN_DIALOG: `${rdxUserKeyEvents}SET_SHOW_LOGIN_DIALOG`,
  UPDATE: `${rdxUserKeyEvents}UPDATE`,
  SIGNUP: `${rdxUserKeyEvents}SIGNUP`,
};

export default rdxUserEvents;
export { rdxUserKeyEvents };
