import { gql } from "@apollo/client";

class UserFragments {
  static userCredential() {
    const name = "userCredential";
    return {
      name: name,
      gql: gql`fragment ${name} on User {
        id
        name
        email
        jwt_token
        jwt_token_expires
      }`,
    };
  }

  static userSocial() {
    const name = "userSocial";
    return {
      name: name,
      gql: gql`fragment ${name} on User {
        id 
        username 
        name 
        surname 
        email 
        facebook_img 
        facebook_id 
        jwt_token 
        jwt_token_expires
      }`,
    };
  }
}

export default UserFragments;
