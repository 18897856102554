import { TypePolicy } from "@apollo/client";

const Offer: TypePolicy = {
  fields: {
    formats: {
      read(_, { readField }) {
        let formats = [];
        const supportedFormats = readField(
          "image_webp_supported_formats",
        ) as string[];
        const logoWebP = readField("image_webp") as string;

        supportedFormats.forEach((f) => {
          formats.push({
            type: f,
            url: logoWebP.replace(":FORMAT", f),
          });
        });

        return formats;
      },
    },
  },
};

export { Offer };
